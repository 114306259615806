import axios from "axios"
import * as Constants from '@/constants'

const apiURL = process.env.REACT_APP_PORTAL_API_URL
const portalURL = process.env.REACT_APP_PORTAL_URL


export let AuthService = (() => {
    //private methods
    function getAuth(){
        return JSON.parse(localStorage.getItem(Constants.localStoreKey))
    }

    //public methods
    return {
        login: async (user, pass) => {
            let response = await axios.post(apiURL+'/login', {
                username: user,
                password: pass
            })
            if(response.data.access_token){
                let authInfo = {
                    username: user,
                    password: pass,
                    access_token: response.data.access_token,
                    id_token: response.data.id_token,
                    token_type: response.data.token_type,
                    expires_in: response.data.expires_in,
                    updated_at: Date.now()
                }
                localStorage.setItem(Constants.localStoreKey, JSON.stringify(authInfo))         
                return true
            } else {
                return false
            }
        },
        tokenIsAboutToExpire: () => {
            const auth = getAuth()
            return auth.updated_at + auth.expires_in - Date.now() < 5000
        },
        refreshToken: async () => {
            const auth = getAuth()
            const loginResponse = await AuthService.login(auth.username, auth.password)
            if (!loginResponse) {
                alert("Logging out due to error refreshing token. Try logging in again.")
                AuthService.logout()
            }
            return loginResponse
        },
        refreshTokenIfAboutToExpire: async () => {
            if (AuthService.tokenIsAboutToExpire()) {
                await AuthService.refreshToken()
            }
        },
        logout: () => {
            localStorage.removeItem(Constants.localStoreKey)
            window.location.reload(false);
        },
        isLoggedIn: () => {
            if(localStorage.getItem(Constants.localStoreKey)){
                return true;    
            }
            return false;
        },
        setRememberEmail: (email) => {
            localStorage.setItem(Constants.localStoreRememberEmailKey, email)   
        },
        getRememberEmail: () => {
            return localStorage.getItem(Constants.localStoreRememberEmailKey)   
        },
        getUsername: () => {
            let auth = getAuth()
            return auth.username
        },
        getPassword: () => {
            let auth = getAuth()
            return auth && auth.password
        },
        getUserAccessToken: () => {
            let auth = getAuth()
            return auth.access_token
        },
        getUserOpenIdAccessToken: () => {
            let auth = getAuth()
            return auth.id_token
        },
        getUserOrgs: async () => {
            let response = await axios.post(apiURL+'/getUserOrgs', {access_token : getAuth().access_token, arcturus_org_id: Constants.arcturusOrgId});
            return response.data;
        },
        getUserInfo: async () => {
            try {
                let response = await axios.post(apiURL+'/getUserInfo', getAuth());
                if (!response.data) {
                    // update login token
                    await this.login(getAuth().username, getAuth().password);
                    response = await axios.post(apiURL+'/getUserInfo', getAuth());
                }
                return response.data;
            }
            catch (error) {
                alert("login error, logging out")
                AuthService.logout()
            }
        },
        // note (MS): not currently used, we use getOrgUsers instead to get all org users at the same time
        getUserInfoByID: async (userID) => {
            try {
                let response = await axios.post(apiURL+'/getUserInfoByID', {userID: userID});
                return response.data;
            }
            catch (error) {
                return "error"
            }
        },
        getOrgUsers: async (orgId) => {
            try {
                let usersPromise = axios.post(apiURL+'/getOrgUsers', {orgId: orgId});
                let usersWithPicsPromise = axios.post(apiURL+'/getAllOrgUsersWithPictures', {orgId: orgId});

                const users = await usersPromise
                const usersWithPics = await usersWithPicsPromise

                for (const user of users.data) {
                    if (usersWithPics.data.includes(user.id)) {
                        user.hasCustomPicture = true
                    }
                }

                return users.data;
            }
            catch (error) {
                return "error"
            }
        },
        putUserInfo: async (info, updateUserInfo) => {
            await axios.post(apiURL + '/putUserInfo', { info: info, access_token: getAuth().access_token } )
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    updateUserInfo(info)
                }
                else {
                    throw new Error(response.status);
                }
            }).catch(error => {
                console.log("error changing user info", error)
                alert("Error changing user info! Please retry, or check your browser console for logs.")
            });
        },
        changeUserPassword: async (info, newPassword) => {
            await axios.post(apiURL + '/changeUserPassword', { info: info, access_token: getAuth().access_token, newPassword: newPassword} )
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    let auth = getAuth()
                    auth.password = newPassword
                    localStorage.setItem(Constants.localStoreKey, JSON.stringify(auth))
                }
                else {
                    throw new Error(response.status);
                }
            }).catch(error => {
                console.log("error changing password", error)
                alert("Error changing password! Please retry, or check your browser console for logs.")
            });
        },
        resetUserPassword: async (passToken, email, newPassword) => {
            try {
                let response = await axios.post(apiURL+'/resetUserPassword', {passToken: passToken, email: email, newPassword: newPassword} )
                
                if (response.status >= 200 && response.status < 300) {
                    return "success"
                }
                else {
                    throw new Error(response.status);
                }
                
            }
            catch (error) {
                console.log("error changing password", error)
                alert("error resetting password, check console for error details")
            }
        },
        sendNewUserInvite: async (info, email, recipientFirstName, recipientLastName, inviterName, orgId, orgName) => {
            try {
                let response = await axios.post(apiURL + '/sendNewUserInvite', { info: info, email: email, recipientFirstName: recipientFirstName, recipientLastName: recipientLastName, inviterName: inviterName, orgId: orgId, orgName: orgName, portalURL: portalURL });
                return response
            }
            catch (error) {
                return { error: error.response.data.error.message }
            }
        },
        acceptInvite: async (invitationToken, invitationId, email, lastName, firstName, password) => {
            try {
                let response = await axios.post(apiURL+'/acceptInvite', {invitationToken: invitationToken,
                                                                            invitationId: invitationId,
                                                                            email: email,
                                                                            lastName: lastName,
                                                                            firstName: firstName,
                                                                            password: password
                                                                        });
                return response.data;
            }
            catch (error) {
                alert("acceptInvite error")
                return "acceptInvite error"
            }
        },
        checkInvite: async (invitationId, email) => {
            try {
                let response = await axios.post(apiURL+'/checkInvite', {    invitationId: invitationId,
                                                                            email: email
                                                                        });
                return response.data;
            }
            catch (error) {
                alert("acceptInvite error")
                return "acceptInvite error"
            }
        },
        sendNewPassToken: async (email) => {
            try {
                let response = await axios.post(apiURL + '/sendNewPassToken', { email: email, portalURL: portalURL });
                return response
            }
            catch (error) {
                return { error: error.message }
            }
        },
        checkPassToken: async (email, token) => {
            try {
                let response = await axios.post(apiURL+'/checkPassToken', { email: email,
                                                                            token: token,
                                                                        });
                return response.data;
            }
            catch (error) {
                console.log(error)
                alert(error.response.data.error.message)
                return "checkPassToken error"
            }
        },

    };
  })();