import React from 'react';

import './moveclipmenu.css'

export default class MoveClipMenu extends React.Component {

    renderMenuItem(name, projectId) {
        return (
            <div data-test="btn-moveTo-project" className="clip-menu-item" style={{width:"240px"}} onClick={() =>this.props.onMoveToProject(projectId)}>
                {name}
            </div>
        )
    }

    render() {

        let menuContent = []
        for (const project of this.props.projects) {
            if (project.id === this.props.currentProjectId) {
                continue;
            }
            menuContent.push(this.renderMenuItem(project.name, project.id))
        }

        return menuContent
    }
}