import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import './App.css';
import * as Constants from './constants'

import Sidebar from './sidebar/sidebar';
import Viewport from './viewport/viewport';
import SignUpPage from './welcome/signuppage';
import ForgotPassPage from './welcome/forgotpasspage';
import LoginPage from './welcome/loginpage';

import { AuthService } from './services/authservice';

import { ScreenDimension } from './screenDimension';
import { Oval } from  'react-loader-spinner'
import { Organization } from '@/models/datatypes'

import { DataService } from '@/services/dataservice';
import { PictureService } from '@/services/pictureservice';


export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticated: AuthService.isLoggedIn(),
            activeView: Constants.viewStates.user,
            activeOrganization: "",
            signInFailed: false,
            userInfo: "",
            organizations: [],
            sidebarPictureRefresher: 0,
            clipOwners: "",
            clips: "",
            projects: "",
            orgViews: "",
            orgDownloads: "",
            computeUsage: "",
            computeEntitlement: "",
            viewEntitlement: "",
        };
    }

    setActiveView(view){
        this.setState({activeView: view})
    }

    setActiveOrganization(organization){
        this.setState({ activeOrganization: organization, orgViews: "", clips: "", projects: "", clipOwners: "", orgDownloads: "", computeUsage: "", computeEntitlement: "", viewEntitlement: ""})
    }

    setOrgHasCustomPicture(organization, value){
        let orgs = this.state.organizations
        for (let i = 0; i < orgs.length; i++) {
            if (orgs[i].id === organization) {
                orgs[i].hasCustomPicture = value
                this.setState({organizations: orgs})
                break
            }
        }
    }

    updateUserInfo (userInfo) {
        let info = this.state.userInfo
        for (const key in userInfo) {
            if (key === "address") {
                continue;
            }
            info[key] = userInfo[key]
        }
        for (const key in userInfo.address) {
            info.address[key] = userInfo.address[key]
        }
        this.setState({userInfo: info})
    }

    getOrgHoverMessage(activeView, activeOrganization) {
        switch(activeView){
            case Constants.viewStates.user:
                return("Current organization: " + activeOrganization.name);
            case Constants.viewStates.clips:
                return("Showing clips for " + activeOrganization.name + " organization");
            case Constants.viewStates.compute:
                return("Current organization: " + activeOrganization.name);
            case Constants.viewStates.downloads:
                return("Showing downloads for " + activeOrganization.name + " organization");
            default:
                return("");
        }
    }

    refreshSidebarPicture() {
        this.setState({sidebarPictureRefresher : this.state.sidebarPictureRefresher+1})
    }

    renderLoggedInView(){
        
        // these two blocks are placed here to handle page reloading without signing out
        if (!this.state.userInfo || !this.state.userInfo.id) {
            this.state.userInfo = {id: "calling"}
            AuthService.getUserInfo().then(info => {

                if (info && info.id && !info.address) {
                    info.address = { country: "CA", streetAddress: "", locality: "", postalCode: "", region: "" }
                }

                if (info && info.id) {
                    this.setState({userInfo : info}) 
                }
                else {
                    AuthService.logout()
                }
            })
        }

        if (this.state.organizations.length === 0) {
            this.state.organizations = "calling"
            AuthService.getUserOrgs().then(orgs => {
                if (orgs.length !== 0) {
                    let orgsAsInDataType = []
                    let arcturusUser = false
                    for (let i = 0; i < orgs.length; i++) {
                        if (orgs[i].id === Constants.arcturusOrgId) {
                            arcturusUser = true
                        }
                        orgsAsInDataType.push( new Organization(orgs[i] ) )
                    }

                    if (arcturusUser) {
                        PictureService.getAllOrgsWithPictures().then( orgsWithPictures => {
                            for (const org of orgsAsInDataType) {
                                if (!orgsWithPictures.includes(org.id)) {
                                    org.hasCustomPicture = false
                                }
                            }
                        })
                    }

                    this.setState({
                        organizations: orgsAsInDataType.sort((a, b) => a.name?.localeCompare(b.name)),
                        activeOrganization: arcturusUser ? orgsAsInDataType.find(org => org.id === Constants.arcturusOrgId) : orgsAsInDataType[0],
                        arcturusUser: arcturusUser
                    })
                }
                else {
                    AuthService.logout()
                }
            })
        }

        
        if (this.state.activeOrganization && this.state.clips === "") {
            this.state.clips = "calling"
            DataService.getAllClipsForOrg(this.state.activeOrganization.id).then (info => {
                this.setState({clips: info.clips, orgViews: info.orgViews})
            })
        }

        // note (MS): it's more convenient to get all Org users than look at all clips and identify the owners because this way
        // we do only one call to 10duke for the whole org instead of one call for each clip owner.
        if (this.state.activeOrganization && this.state.clipOwners === "") {
            this.state.clipOwners = "calling"
            AuthService.getOrgUsers(this.state.activeOrganization.id).then(users => {
                this.setState({clipOwners: users})
            })
        }

        //render a loading spinner if awaiting info
        if(!this.state.userInfo || !this.state.userInfo.id === "calling" || this.state.organizations === "calling" || this.state.clips === "calling" || this.state.clipOwners === "calling") {
            return <div className="spinner" data-test="load-spinner">
                <Oval
                height={100}
                width="100%"
                color="#9D8094"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#9D8094"
                strokeWidth={2}
                strokeWidthSecondary={2}         
                />
            </div>    
        }
        
        return(       
            <ScreenDimension>
            { (width, height) =>       
            
                <div className="App">
                    
                    <Sidebar
                        setActiveView={view => this.setActiveView(view)}
                        activeView={this.state.activeView}
                        hidden={width <= Constants.WIDTH_FOR_SHOWING_SIDEBAR}
                        userInfo = {this.state.userInfo}
                        activeOrganization={this.state.activeOrganization}
                        setOrgHasCustomPicture={(organization, value) => this.setOrgHasCustomPicture(organization, value)}
                        orgHoverMessage={this.getOrgHoverMessage(this.state.activeView, this.state.activeOrganization)}
                        sidebarPictureRefresher={this.state.sidebarPictureRefresher}
                    />
                    <Viewport
                        setActiveView={view => this.setActiveView(view)}
                        activeOrganization={this.state.activeOrganization}
                        setActiveOrganization={organization => this.setActiveOrganization(organization)}
                        setOrgHasCustomPicture={(organization, value) => this.setOrgHasCustomPicture(organization, value)}
                        setOrgExpanded={(organization, value) => this.setOrgExpanded(organization, value)}
                        organizations={this.state.organizations}
                        activeView={this.state.activeView}
                        width={width}
                        sidebarHidden={width <= Constants.WIDTH_FOR_SHOWING_SIDEBAR}
                        height={height}
                        userInfo = {this.state.userInfo}
                        refreshSidebarPicture = {() => this.refreshSidebarPicture()}
                        updateUserInfo = {userInfo => this.updateUserInfo(userInfo)}
                        arcturusUser = {this.state.arcturusUser}
                        clips={this.state.clips}
                        ownersInfo={this.state.clipOwners}
                        projects={this.state.projects}
                        setOrgProjects={projects => this.setState({projects: projects})}
                        orgViews={this.state.orgViews}
                        orgDownloads={this.state.orgDownloads}
                        setOrgDownloads={downloads => this.setState({ orgDownloads: downloads })}
                        computeUsage={this.state.computeUsage}
                        setComputeUsage={usage => this.setState({computeUsage: usage})}
                        computeEntitlement={this.state.computeEntitlement}
                        setComputeEntitlement={ent => this.setState({computeEntitlement: ent})}
                        viewEntitlement={this.state.viewEntitlement}
                        setViewEntitlement={ent => this.setState({viewEntitlement: ent})}
                    />

                </div>
            }
            </ScreenDimension> 
        );   
    }

    processLogInAttempt(auth) {
        this.setState({authenticated: auth, signInFailed: !auth})
    }               

    renderLoggedOutView(){
        return(
            <div className="App">
                <LoginPage
                signInFailed={this.state.signInFailed}
                authenticate={(username, password) => AuthService.login(username, password).then(auth => this.processLogInAttempt(auth)
                )}
                />
            </div>
        )
    }

    renderHomeRoute(){
        return this.state.authenticated ? this.renderLoggedInView() : this.renderLoggedOutView();
    }

    renderSignUpRoute(){
        return (
            <div className="App">
                <SignUpPage/>
            </div>
        );
    }
    
    renderForgotPasswordRoute(){
        return (
            <div className="App">
                <ForgotPassPage/>
            </div>
        );
    }

    render(){
        return(
            <Router>
                <Routes>
                    <Route path="/" element={this.renderHomeRoute()}/>
                    <Route path="/signup" element={this.renderSignUpRoute()}/>
                    <Route path="/forgot" element={this.renderForgotPasswordRoute()}/>
                </Routes>
            </Router>
        );
    }
}
