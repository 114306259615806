import React from 'react'
import { Oval } from 'react-loader-spinner'

import './downloadsview.css'

import * as Constants from '@/constants'

import DownloadsHeaderItem from './downloadsheaderitem/downloadsheaderitem';

import downloadsBackground from "@/images/viewport/downloadsview/DownloadsBackground.png"
import holosuiteImage from "@/images/viewport/downloadsview/HoloSuite.svg"
import HSPUnityImage from "@/images/viewport/downloadsview/HSPunity.svg"
import HSPUnrealImage from "@/images/viewport/downloadsview/HSPunreal.svg"
import HSPWebImage from "@/images/viewport/downloadsview/HSPweb.svg"
import SVFUnityImage from "@/images/viewport/downloadsview/SVFunity.svg"
import SVFUnrealImage from "@/images/viewport/downloadsview/SVFunreal.svg"
import SVFWebImage from "@/images/viewport/downloadsview/SVFweb.svg"
import holosuiteIcon from "@/images/viewport/downloadsview/Holosuite_icon.svg"
// note: PlayerIcon.svg matches Holosuite_icon.svg until we get updated icon design
import PlayerIcon from "@/images/viewport/downloadsview/PlayerIcon.svg"
import downloadIcon from "@/images/viewport/downloadsview/Downloads_icon.svg"
import releaseNotesIcon from "@/images/viewport/downloadsview/Release_notes_icon.svg"
import LTSIcon from "@/images/viewport/downloadsview/LTS.svg"
import unsupportedIcon from "@/images/viewport/downloadsview/unsupported.svg"

import { DownloadsService } from '@/services/downloadsservice';

export default class DownloadsView extends React.Component {
    constructor(props) {
        super(props);

        document.title = "Downloads - HoloSuite Portal"

        
        this.state = {
            softwareNames: ["HoloSuite", "HSPUnity", "HSPUnreal", "HSPWeb", "SVFUnity", "SVFUnreal", "SVFWeb"],
            archiveVisible: [false, false, false, false, false, false, false],
        }

        if (this.props.activeOrganization.name && this.props.downloads === "") {
            DownloadsService.getDownloads(this.props.activeOrganization.name).then(async response => {
                this.props.setOrgDownloads(response)
                this.forceUpdate()
            })
        }
    }

    getDisplayDataForSoftwareName(name) {
        if (name === "HoloSuite") {
            return {bigImgSrc: holosuiteImage, smallImgSrc: holosuiteIcon, archiveLabel: "HOLOSUITE ARCHIVE", displayName: "HoloSuite"}
        }
        if (name === "HSPUnity") {
            return {bigImgSrc: HSPUnityImage, smallImgSrc: PlayerIcon, archiveLabel: "HSP UNITY ARCHIVE", displayName: "HSP Unity"}
        }
        if (name === "HSPUnreal") {
            return {bigImgSrc: HSPUnrealImage, smallImgSrc: PlayerIcon, archiveLabel: "HSP UNREAL ARCHIVE", displayName: "HSP Unreal"}
        }
        if (name === "HSPWeb") {
            return {bigImgSrc: HSPWebImage, smallImgSrc: PlayerIcon, archiveLabel: "HSP WEB ARCHIVE", displayName: "HSP Web"}
        }
        if (name === "SVFUnity") {
            return {bigImgSrc: SVFUnityImage, smallImgSrc: PlayerIcon, archiveLabel: "SVF UNITY ARCHIVE", displayName: "SVF Unity"}
        }
        if (name === "SVFUnreal") {
            return {bigImgSrc: SVFUnrealImage, smallImgSrc: PlayerIcon, archiveLabel: "SVF UNREAL ARCHIVE", displayName: "SVF Unreal"}
        }
        if (name === "SVFWeb") {
            return  {bigImgSrc: SVFWebImage, smallImgSrc: PlayerIcon, archiveLabel: "SVF WEB ARCHIVE", displayName: "SVF Web"}
        }
        return {bigImgSrc: holosuiteIcon, smallImgSrc: holosuiteIcon, archiveLabel: "UNCATEGORIZED ARCHIVE", displayName: "unknown software"}
    }

    renderTopDownloadTitle(width, software) {
        const softwareName = software.split(' ')[0];
        const softwareVersion = software.substring(softwareName.length)
        return (
            <div className={width > Constants.WIDTH_FOR_DESKTOP_VIEW ? "horizontal-container align-left" : "horizontal-container center"}>
                <div className="align-left center-vert">
                    <img data-test={"field-title-" + softwareName} className="align-left holosuite" src={this.getDisplayDataForSoftwareName(softwareName).bigImgSrc} alt="software Logo"></img> 
                    <div data-test="field-product-version" className={width > Constants.WIDTH_FOR_DESKTOP_VIEW ? "version-label" : width > Constants.WIDTH_FOR_SHOWING_SIDEBAR ? "version-label-small" : "version-label-smaller"}> {softwareVersion} </div>
                </div>
            </div>
        );
    }

    renderDownloadButton(link, width, forceLabel) {
        if (width > Constants.WIDTH_FOR_DESKTOP_VIEW || forceLabel)
        {
            return ( 
                <div className="horizontal-container center">
                    <div className="download-button align-left-not-justify center" onClick={() => window.open(link, '_self')}>
                        <div data-test="btn-download" className="download-button-icon"> <img src={downloadIcon}  alt="Download Icon"></img> </div>
                        <div className="button-label hidden-child" style={{paddingRight: "10px"}}> DOWNLOAD </div>
                    </div>
                </div>
            );
        }
        return ( 
            <div className="downloads-container-right-padding center">
                <div className="button-no-label center" onClick={() => window.open(link, '_self')}>
                    <div data-test="btn-download" className="download-button-icon"> <img src={downloadIcon}  alt="Download Icon"></img> </div>
                </div>
            </div>
        );
    }

    renderReleaseNotesButton(link, width, forceLabel) {
        if (width > Constants.WIDTH_FOR_DESKTOP_VIEW || forceLabel)
        {
            return ( 
                <div className="downloads-container-right-padding center-top-padding">
                    <div className="release-notes-button align-right-not-justify center" onClick={() => window.open(link)}>
                        <div data-test="btn-releaseNotes" className="button-label hidden-child" style={{paddingLeft: "10px"}}> RELEASE NOTES </div>        
                        <div className="release-notes-button-icon"> <img src={releaseNotesIcon} alt="Release Notes Icon"></img> </div>
                    </div>
                </div>
            );
        }
        return ( 
            <div className="downloads-container-right-padding center">
                <div className="button-no-label align-right center" onClick={() => window.open(link)}>
                    <div data-test="btn-releaseNotes" className="release-notes-button-icon"> <img src={releaseNotesIcon} alt="Release Notes Icon"></img> </div>
                </div>
            </div>
        );
    }

    renderTopDownloadRows(width, currentVersions) {
        let content = []
        for (const currentVersion of currentVersions) {
            content.unshift(this.renderTopDownloadRow(width, currentVersion))
        }
        return ( 
            <div data-test="container-promoted-downloads" className="vertical-container">
                {content}
            </div>
        )
    }

    renderTopDownloadRow(width, currentVersion) {
        if (width > Constants.WIDTH_FOR_DESKTOP_VIEW)
        {
            return ( 
                 <div data-test="card-productDownload" className="horizontal-container downloads-container-left-padding downloads-container-top-rows-padding">
                    <div style={{width: "50%"}}>
                       {this.renderTopDownloadTitle(width, currentVersion.version)}
                    </div>
                    <div style={{width: "25%"}}>
                        {this.renderDownloadButton(currentVersion.download_link, width)}
                    </div>
                    <div style={{width: "25%"}}>
                        {this.renderReleaseNotesButton(currentVersion.release_notes_link, width)}
                    </div>
                </div>
             );
        }
        if (width > Constants.WIDTH_FOR_MOBILE_VIEW)
        {
            return ( 
                <div data-test="card-productDownload" className="horizontal-container downloads-container-left-padding downloads-container-top-rows-padding">
                    <div style={{width: "70%"}}>
                        {this.renderTopDownloadTitle(width, currentVersion.version)}
                    </div>
                    <div style={{width: "15%"}}>
                        {this.renderDownloadButton(currentVersion.download_link, width)}
                    </div>
                    <div style={{width: "15%"}}>
                        {this.renderReleaseNotesButton(currentVersion.release_notes_link, width)}
                    </div>
                </div>
            );
        }
        return ( 
            <div data-test="card-productDownload" className="vertical-container downloads-container-top-rows-padding">
                <div className="horizontal-container full-width">
                    {this.renderTopDownloadTitle(width, currentVersion.version)}
                </div>
                <div className="horizontal-container full-width center">
                    <div className="horizontal-container" style={{width: "360px"}}>
                        <div style={{width: "50%"}}>
                            {this.renderDownloadButton(currentVersion.download_link, width)}
                        </div>
                        <div style={{width: "50%"}}>
                            {this.renderReleaseNotesButton(currentVersion.release_notes_link, width)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    renderDownloadLabel(softwareName, version, modifier) {
        if (modifier === "LTS") {
            return ( 
                <>
                    <div className="row-version-label align-left center"> {softwareName} {version}</div>                    
                    <img className="LTS-label center" src={LTSIcon} alt="Long Term Support"></img>
                    <div id="LTS-sign-container"><div className="LTS-sign"> <span className="bold-text">Long Term Supported versions</span> will continue to work with existing cloud processing. </div></div>
                </>
            );
        }
        if (modifier === "unsupported") {
            return (
                <>
                    <div className="row-version-label align-left center" > {softwareName} {version}</div>
                    <img className="LTS-label center" src={unsupportedIcon} alt="Unsupported"></img>
                    <div id="LTS-sign-container"><div className="LTS-sign"> <span className="bold-text">Unsupported</span> versions can be accessed but will not work with existing cloud processing. </div></div>
                </>
            );
        }
        return (<div className="row-version-label align-left center"> {softwareName} {version} </div>);
    }

    renderRowDownloadTitle(width, icon, softwareName, version, modifier) {
       return (  
            <div className="horizontal-container align-left" >
                <div className="align-left" style={width > Constants.WIDTH_FOR_MOBILE_VIEW ? {width: "270px"} : {width: "170px"}}>
                   <div><img className="align-left holosuite" src={icon} alt={"software Icon"}></img> </div>
                    {this.renderDownloadLabel(softwareName, version, modifier)}
                </div>
            </div>
        );
    }

    renderDownloadRow(width, icon, softwareName, version, modifier, download_link, release_notes_link) {
       if (width <= Constants.WIDTH_FOR_MOBILE_VIEW)
        {
            return ( 
                <div key={version} data-test="card-archive-product" className="row-container">
                    <div className="horizontal-container full-width">
                        <div style={{width: "200px"}}>
                           {this.renderRowDownloadTitle(width, icon, softwareName, version, modifier)}
                        </div>
                        <div style={{width: "60px"}}>
                            {this.renderDownloadButton(download_link, width)}
                        </div>
                        <div style={{width: "50px"}}>
                            {this.renderReleaseNotesButton(release_notes_link, width)}
                        </div>
                    </div>
                </div>
           );
        }
        return ( 
            <div key={version} data-test="card-archive-product" className="downloads-container-left-padding row-container">
                <div className="horizontal-container" style={{width: "100%"}}>
                    <div style={width > Constants.WIDTH_FOR_DESKTOP_VIEW ? {width: "50%"} : {width: "70%"}}>
                        {this.renderRowDownloadTitle(width, icon, softwareName, version, modifier)}
                    </div>
                    <div style={width > Constants.WIDTH_FOR_DESKTOP_VIEW ? {width: "25%"} :{width: "15%"}}>
                        {this.renderDownloadButton(download_link, width)}
                    </div>
                    <div style={width > Constants.WIDTH_FOR_DESKTOP_VIEW ? {width: "25%"} : {width: "15%"}}>
                        {this.renderReleaseNotesButton(release_notes_link, width)}
                    </div>
                </div>
            </div>
        )
    }
    
    renderDownloadArchive(width, archiveData, displayData) {
        let ans = [];
        for (let i = 0; i < archiveData.length; i++) {
            const softwareName = archiveData[i].version.split(' ')[0];
            const version = archiveData[i].version.substring(softwareName.length);
            const modifier = archiveData[i].modifier;
            const download_link = archiveData[i].download_link;
            const release_notes_link = archiveData[i].release_notes_link;
            ans.push(this.renderDownloadRow(width, displayData.smallImgSrc, displayData.displayName, version, modifier, download_link, release_notes_link));
        }
        return ans;
    }

    setArchiveVisibility(state,index) {
        let archiveVisible = this.state.archiveVisible
        archiveVisible[index] = state
        this.setState({archiveVisible : archiveVisible})
    }
    
    // Note: when this function is called archiveData should have at least one item and all of them should be versions of the same software
    renderDownloadArchiveContainer(width, archiveData) {
        const softwareName = archiveData[0].version.split(' ')[0]
        let index = this.state.softwareNames.indexOf( softwareName )
        let displayData = this.getDisplayDataForSoftwareName( softwareName )

        return (
            <>
                <DownloadsHeaderItem label={displayData.archiveLabel} archiveVisible={this.state.archiveVisible[index]} setArchiveVisible={(state) => this.setArchiveVisibility(state,index)}>
                </DownloadsHeaderItem>
                    
                <div style={{height: "15px"}}/>

                <div id="content" className={this.state.archiveVisible[index] ? "downloads-content downloads-expanded" : "downloads-content"}>
                    <div data-test="container-archive" className="inside">
                        {this.renderDownloadArchive(this.props.width, archiveData, displayData)}
                    </div>
                </div>

                <div style={{ height: "15px" }} />
            </>
        )
    }

    renderDownloadArchives(width, sortedArchiveData) {
        let content = []
        for (const archiveData of sortedArchiveData) {
            if (archiveData.length > 0) {
                content.unshift(this.renderDownloadArchiveContainer(width, archiveData))
            }
        }
        return ( 
            <div className="vertical-container">
                {content}
            </div>
        )
    }

    render() {
        //If loading show spinner
        if(this.props.downloads === ""){
            return <div className="spinner" data-test="load-spinner-downloads">
                <Oval
                height={100}
                width="100%"
                color="#9D8094"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#9D8094"
                strokeWidth={2}
                strokeWidthSecondary={2}
                />
            </div>    
        }

        let currentVersions = []
        let sortedArchiveData = []
        for (let i = 0; i < this.state.softwareNames.length; i++) {
            sortedArchiveData.push([])
        }
        const downloadsData = this.props.downloads
        for (let i = 0; i < downloadsData.length; i++) {
            if (downloadsData[i].modifier === "Current Version") {
                currentVersions.push( downloadsData[i] );
                continue;
            }
            let index = this.state.softwareNames.indexOf( downloadsData[i].version.split(' ')[0] )
            if (index !== -1) {
                sortedArchiveData[index].push( downloadsData[i] )
            }
        }
        currentVersions.sort((a, b) => this.state.softwareNames.indexOf(a.version.split(' ')[0]) < this.state.softwareNames.indexOf(b.version.split(' ')[0]) ? 1 : -1 )
        sortedArchiveData = sortedArchiveData.filter(a => {return a.length > 0})
        sortedArchiveData.sort((a, b) => this.state.softwareNames.indexOf(a[0].version.split(' ')[0]) < this.state.softwareNames.indexOf(b[0].version.split(' ')[0]) ? 1 : -1 )
        for (let i = 0; i < sortedArchiveData.length; i++) {
            sortedArchiveData[i].sort((a, b) => b.version.split(' ')[1].localeCompare(a.version.split(' ')[1]))
        }

        const viewportClassNames = `ViewportView downloads-view`;
        
        // Background original dimension
        const origBackgroundHeight = 719
        const origBackgroundWidth = 1128
        // Background width to preserve ratio and cover viewport height
        const backgroundWidth = this.props.height * origBackgroundWidth / origBackgroundHeight
        // where to "really" start background overlay's linear gradient as a percentage of background width
        const linearGradientPercentage = backgroundWidth - this.props.width > 0 ? ( backgroundWidth - this.props.width ) / backgroundWidth * 100 : 0

        return (
            <div className={viewportClassNames} style={{backgroundImage: `linear-gradient(to right, #1A1A25, #1A1A25 ${linearGradientPercentage}%, #1C065F00), url(${downloadsBackground})`,
                                            backgroundRepeat:"no-repeat", backgroundSize:backgroundWidth+"px 100%", 
                                            backgroundPosition: this.props.width - backgroundWidth + "px 0",
                                            backgroundAttachment: "fixed"}}>
                <div className={this.props.width > Constants.WIDTH_FOR_SHOWING_SIDEBAR ? "downloads-container" : "downloads-container-cellmode"}>
                
                    <div style={{height: "48px"}}/>

                    {this.renderTopDownloadRows(this.props.width, currentVersions)}

                    <div style={{height: "20px"}}/>
                    
                    {this.renderDownloadArchives(this.props.width, sortedArchiveData)}

                    <div style={{ height: "35px" }} />

                </div> 
            </div>


        );
    }
    
}