import React from 'react';

import './welcome.css'

import holoSuiteImage from "@/images/welcome/FullLogo_White.svg";

import WelcomeInput from './welcomeinput';

import { AuthService } from '@/services/authservice';

export default class LoginSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            canLogin: false,
            email: AuthService.getRememberEmail(),
            password: "",
            rememberEmail: AuthService.getRememberEmail(),
            sendingNewPassToken: false,
        }
    }

    validateKeys() {
        const validateString = function (value) {
            return value !== null && value !== undefined && value.length > 0;
        }

        let success =
            validateString(this.state.email) &&
            validateString(this.state.password)
        return success
    }

    checkIfCanLogin() {
        if (this.validateKeys()) {
            this.setState({ canLogin: true })
        }
        else {
            this.setState({ canLogin: false })
        }
    }

    updateInput(key, newValue) {
        const state = this.state;
        state[key] = newValue
        this.setState(state)

        this.checkIfCanLogin();
    }

    onEnterPressed() {
        if (this.state.canLogin) {
            this.submitForm()
        }
    }

    renderFormContent() {
        return (
            <div className="login-content">

                <div className="login-help-error" />
                <WelcomeInput
                    label="Email"
                    inputUpdate={(value) => this.updateInput("email", value)}
                    inputValue={this.state.email}
                    focusFirst
                    onEnterPressed={() => this.onEnterPressed()}
                />
                <div className="login-help-error" />
                <WelcomeInput
                    label="Password"
                    password={true}
                    inputUpdate={(value) => this.updateInput("password", value)}
                    inputValue={this.state.password}
                    onEnterPressed={() => this.onEnterPressed()}
                />

            </div>
        )
    }

    async onForgotPassword() {
        if (!this.state.sendingNewPassToken) {
            this.setState({ sendingNewPassToken: true })
            const res = await AuthService.sendNewPassToken(this.state.email)
            if (res.error) {
                alert("Could not send recovery email to " + this.state.email + ". Please make sure that's a valid user email.")
            }
            if (res.status === 200) {
                alert("A password recovery email was sent to " + this.state.email + ". Please follow the instructions in that email to reset your password.")
            }
            this.setState({ sendingNewPassToken: false })
        }
    }

    renderFormFooter() {
        return (
            <div className="login-content vertical-container">
                <div style={{ "minHeight": "4px" }} />
                <div className="normal-text horizontal-container" style={{ "justifyContent": "flex-start", marginLeft: "16px", "fontSize": "12px" }}>

                    <div className={this.state.sendingNewPassToken ? "forgot-pass-link-disabled" : "forgot-pass-link"} data-test="btn-forgot-password" onClick={() => this.onForgotPassword()}>
                        Forgot password?
                    </div>
                </div>

                <div style={{ "minHeight": "13px" }} />

                <div className="normal-text horizontal-container">
                    <input type="checkbox" data-test="cbox-remember-email" className="checkbox" id="remember_email_checkbox" checked={this.state.rememberEmail} onChange={(event) => this.setState({ rememberEmail: event.target.checked })} />
                    <div className="full-width horizontal-container" style={{ "justifyContent": "flex-start", marginLeft: "10px" }}>
                        <div>
                            Remember my email on this computer
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    submitForm() {
        if (this.state.rememberEmail) {
            AuthService.setRememberEmail(this.state.email)
        }
        else {
            AuthService.setRememberEmail("")
        }
        this.props.authenticate(this.state.email, this.state.password);
    }

    renderLoginButton() {
        let buttonClass = "login-confirm-button";
        let onClick = () => { };
        if (!this.state.canLogin) {
            buttonClass = "login-confirm-button-disabled";
        }
        else {
            onClick = () => this.submitForm();
        }
        return (
            <div className="login-content" style={{ "marginTop": "33px" }}>
                <div className={buttonClass} data-test="btn-login" onClick={onClick}>
                    <div className="login-confirm-button-text">
                        PORTAL LOGIN
                    </div>
                </div>
            </div>
        )
    }

    renderSignInErrorMsg() {
        return (
            <div className="normal-text horizontal-container signup-prompt" data-test="text-warning" style={{ color: "red" }} >
                <div>
                    Wrong Username or Password
                </div>
            </div>
        )
    }

    renderHeader() {
        return (
            <div className="">
                <img src={holoSuiteImage} className="login-footer-image" alt={"login footer"} />
            </div>
        )
    }

    render() {
        return (
            <div className="sidebar login-sidebar">
                {this.renderHeader()}
                {this.renderFormContent()}
                {this.renderFormFooter()}
                {this.renderLoginButton()}
                {this.props.signInFailed && this.renderSignInErrorMsg()}
            </div>
        );
    }
}
