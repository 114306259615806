import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Oval } from 'react-loader-spinner'
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './clipsview.css'
import '../clips.css'
import * as Constants from '@/constants'

import PortalClipListItem from './portalcliplistitem/portalcliplistitem';
import ClipListRow from './cliplistrow/cliplistrow';

import nameIcon from "@/images/viewport/clipsview/Name_icon.svg";
import lengthIcon from "@/images/viewport/clipsview/Length_icon.svg"
import uniqueViewsIcon from "@/images/viewport/clipsview/UniqueViews_icon.svg"
import totalViewsIcon from "@/images/viewport/clipsview/TotalViews_icon.svg"
import updatedIcon from "@/images/viewport/clipsview/DateTime_icon.svg"
import searchIcon from "@/images/viewport/clipsview/Search_icon.svg"
import watchedIcon from "@/images/viewport/clipsview/Watched_icon.svg"
import nameIcon_selected from "@/images/viewport/clipsview/Name_icon_selected.svg";
import lengthIcon_selected from "@/images/viewport/clipsview/Length_icon_selected.svg"
import uniqueViewsIcon_selected from "@/images/viewport/clipsview/UniqueViews_icon_selected.svg"
import totalViewsIcon_selected from "@/images/viewport/clipsview/TotalViews_icon_selected.svg"
import updatedIcon_selected from "@/images/viewport/clipsview/DateTime_icon_selected.svg"
import watchedIcon_selected from "@/images/viewport/clipsview/Watched_icon_selected.svg"

import { DataService } from '@/services/dataservice';
import { AuthService } from '@/services/authservice';
import { PictureService } from '@/services/pictureservice';

import { Project } from '@/models/datatypes'



export default class ClipsView extends React.Component {
    constructor(props) {
        super(props);

        document.title = "Clips - HoloSuite Portal"

        this.sortModes = {
            none: "none",
            name: "name",
            updated: "updated",
            length: "length",
            uniqueViews: "uniqueViews",
            totalViews: "totalViews",
            owner: "owner",
            watched: "watched",
            ascending: "ascending",
            descending: "descending",
        }

        this.state = {
            sortOrder: this.sortModes.ascending,
            sortMode: this.sortModes.name,
            loading: !this.props.projects,
            searchFilter: "",
            searchVisible: false,
            dragging: false,
            draggingId: "",
            uploadedOrGenerated: "",
            imgWidth: "",
            imgHeight: "",
            imgRef: ""
        }

        if(!this.props.projects && this.state.loading && this.props.clips && this.props.activeOrganization.name){ 
            DataService.getAllProjectsForOrganization(this.props.activeOrganization.id).then(async response => {
                //Alphabetize projects
                let projects = response.sort((a, b) => a.name?.localeCompare(b.name))
                // Default Project should go first
                projects.unshift(new Project({projectID: 0, name: "Default Project" } ))

                for (const clip of this.props.clips) {

                    const ownerInfo = this.props.ownersInfo.find(user => user.id === clip.ownerID)
                    clip.ownerInfo.firstName = ownerInfo ? ownerInfo.firstName : "?"
                    clip.ownerInfo.lastName = ownerInfo ? ownerInfo.lastName : "?"
                    clip.ownerInfo.hasCustomPicture = ownerInfo ? ownerInfo.hasCustomPicture : false
                    if (!ownerInfo) {
                        console.log("clip owner ID not found in Org users", clip.ownerID) //, this.props.ownersInfo
                    }
                    const project = this.findProjectById(projects, clip.projectID)
                    project.clips.push(clip)
                }

                this.props.setOrgProjects(projects)

                this.setState({
                    loading: false
                })
            })
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onBeforeDragStart = this.onBeforeDragStart.bind(this);
    }

    findProjectById(projects, id) {
        for (let i = 0; i < projects.length; i++) {
            if (String(projects[i].id) === String(id)) {
                return projects[i]
            }
        }
        // default to Default Project
        return projects[0]
    }

    findProjectIndexById(projects, id) {
        for (let i = 0; i < projects.length; i++) {
            if (String(projects[i].id) === String(id)) {
                return i
            }
        }
        // default to Default Project
        return 0
    }

    async moveClipToProject(clip, sourceProjectId, destProjectId) {

        let projects = this.props.projects;
        let sourceProject = this.findProjectById(projects, sourceProjectId)
        let destProject = this.findProjectById(projects, destProjectId)
        clip.projectID = destProjectId
        destProject.clips.push(clip);
        sourceProject.clips.splice(sourceProject.clips.indexOf(clip), 1);
        destProject.clips = this.getSortedClips(destProject);
        sourceProject.clips = this.getSortedClips(sourceProject);
        this.setState({projects: projects});

        this.setState({ working: true})
        const resp = await DataService.moveClipToProject(clip.uri, 
                                            clip.streamApiURL,
                                            destProjectId, 
                                            this.props.activeOrganization.id, 
                                            AuthService.getUserAccessToken(), 
                                            AuthService.getUserOpenIdAccessToken() )

        if (resp !== "success") {
            alert("Clip Moving failed! Please retry, or check your browser console for logs.")
            // restore clip to old location
            clip.projectID = sourceProjectId
            sourceProject.clips.push(clip);
            destProject.clips.splice(destProject.clips.indexOf(clip), 1);
            destProject.clips = this.getSortedClips(destProject);
            sourceProject.clips = this.getSortedClips(sourceProject);
            this.setState({projects: projects});
        }
        this.setState({ working: false})
    }

    async onDragEnd(result) {
        this.setState({dragging: false, draggingId: ""});
        if (result.source && result.destination && result.source.droppableId !== result.destination.droppableId){
            let sourceProject = this.findProjectById(this.props.projects, result.source.droppableId)
            const clipToMove = sourceProject.clips[result.source.index]
            this.moveClipToProject(clipToMove, result.source.droppableId, result.destination.droppableId)
        }
    }

    onBeforeDragStart(e) {
        this.setState({dragging: true, draggingId: e.draggableId});
    }

    async createNewProject() {
        let projects = this.props.projects;

        let newProjectName = "New Project"
        let projectNames = []
        projects.forEach(project => {
            projectNames.push(String(project.name))
        })
        if (projectNames.includes("New Project")) {
            let counter = 1
            while (projectNames.includes("New Project " + String(counter))) {
                counter++;
            }
            newProjectName = "New Project " + String(counter)
        }

        this.setState({ working: true})
        const newProjectData = await DataService.createNewProject(newProjectName,
                                                this.props.activeOrganization.id, 
                                                AuthService.getUserAccessToken(), 
                                                AuthService.getUserOpenIdAccessToken()
                                            )
        const newProjectId = newProjectData.newProjectId
        if (newProjectId && newProjectId !== "-1") {
            projects.push(new Project({ projectID: newProjectId, name: newProjectName }, newProjectData.newProjectStreamApiURL))
            this.setState({ projects: projects });
        }
        else {
            alert("New Project Creation failed! Please retry, or check your browser console for logs.")
        }
        this.setState({ working: false})
    }

    async renameProject(projectId, streamApiURL, newName) {
        let projects = this.props.projects;
        let project = this.findProjectById(projects, projectId)
        
        this.setState({ working: true})
        const res = await DataService.renameProject(projectId, 
                                        streamApiURL,
                                        newName, 
                                        this.props.activeOrganization.id, 
                                        AuthService.getUserAccessToken(),
                                        AuthService.getUserOpenIdAccessToken() )
                                        
        if (res !== "success") {
            alert("Project Renaming failed! Please retry, or check your browser console for logs.")
            // restore project old name
            document.getElementById(projectId).innerHTML = project.name;
            this.setState({ working: false})
        }
        else {
            project.name = newName;
            this.setState({ projects: projects });
            this.setState({ working: false})
        }
    }

    async deleteProject(projectId) {
        let projects = this.props.projects;
        let project = this.findProjectById(projects, projectId)

        if (project.clips.length !== 0) {
            alert ("project should be empty")
            return
        }

        this.setState({ working: true})
        const res = await DataService.deleteProject(projectId, 
                                    project.streamApiURL,
                                    this.props.activeOrganization.id, 
                                    AuthService.getUserAccessToken(),
                                    AuthService.getUserOpenIdAccessToken() )
        if (res !== "success") {
            alert("Project Deleting failed! Please retry, or check your browser console for logs.")
        }
        else {
            projects.splice(this.findProjectIndexById(projects, projectId), 1);
            this.setState({ projects: projects });
        }
        this.setState({ working: false})
    }

    confirmDeleteClip(clipId) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div data-test="popup-clip-delete" className='confirm-delete-clip-popup'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this clip?</p>
                        <div className="horizontal-container">
                            <div data-test="btn-clip-delete-cancel" className="portal-button" onClick={onClose}>CANCEL</div>
                            <span style={{ width: "20px" }} />
                            <div data-test="btn-clip-delete-confirm" className="portal-button" onClick={() => {
                                this.deleteClip(clipId);
                                onClose();
                            }}>DELETE</div>
                        </div>
                    </div>
                );
            }
        });
    };

    async deleteClip(clipId) {
        let projects = this.props.projects;
        for (const project of projects){
            let clips = project.clips
            for(const [clipIndex, clip] of clips.entries()) {
                if (clip.id === clipId) {
                    this.setState({ working: true})
                    const res = await DataService.deleteClip(clip.uri,
                                                clip.streamApiURL,
                                                this.props.activeOrganization.id,
                                                AuthService.getUserAccessToken(),
                                                AuthService.getUserOpenIdAccessToken() )
                    if (res !== "success") {
                        alert("Clip Delete failed! Please retry, or check your browser console for logs.")
                    }    
                    else {
                        clips.splice(clipIndex, 1);
                        this.setState({ projects: projects });
                    }
                    this.setState({ working: false})
                    break;
                }
            }
        }
    }

    async renameClip(clipId, newName) {
        let projects = this.props.projects;
        for (const project of projects) {
            for (const clip of project.clips) {
                if (clip.id === clipId) {
                    this.setState({ working: true})
                    const res = await DataService.renameClip(clip.uri, 
                                                    clip.streamApiURL,
                                                    newName, 
                                                    this.props.activeOrganization.id, 
                                                    AuthService.getUserAccessToken(), 
                                                    AuthService.getUserOpenIdAccessToken() )
                    if (res !== "success") {   
                        alert("Clip Rename failed! Please retry, or check your browser console for logs.")
                        // restore clip's old name
                        document.getElementById(clipId).innerHTML = clip.name;
                        this.setState({ working: false})
                    }
                    else {
                        clip.name = newName;
                        this.setState({ projects: projects, working: false})
                    }
                }
            }
        }
    }

    setSortMode(mode){
        if (this.state.sortMode === mode){
            return;
        }
        
        let order = this.sortModes.descending;
        if (mode === this.sortModes.name || mode === this.sortModes.length) {
            order = this.sortModes.ascending;
        }

        this.setState({
            sortMode: this.sortModes[mode],
            sortOrder: order
        });
    }

    getOwnerNameFromClip(clip){
        return clip.ownerInfo.firstName + " " + clip.ownerInfo.lastName;
    }

    convertDurationToString(duration){
        if (duration > 10000000 || duration <= 0) {
            return "?"
        }
        const zeroPad = (num, places) => String(num).padStart(places, '0')
        const minutes = parseInt(duration / 60);
        const seconds = duration % 60;
        return minutes + ":" + zeroPad(seconds, 2)
    }

    async onSelectClipFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", async () => 
                {
                    var image = new Image();
                    image.src = reader.result;
                    await image.decode();
                    
                    // Resize the image if needed
                    var canvas = document.createElement('canvas'),
                        max_width = this.props.width / 3 * 2 - 20,
                        max_height = this.props.height / 3 * 2 - 100,
                        width = image.width,
                        height = image.height;
                    if (width > max_width) {
                        height *= max_width / width;
                        width = max_width;
                    }
                    if (height > max_height) {
                        width *= max_height / height;
                        height = max_height;
                    }

                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    // Note (MS): could be changed to png if we want to allow transparency for clip thumbnails
                    const dataUrl = canvas.toDataURL('image/jpeg');
                    this.props.setThumbnailToCrop(dataUrl.length > 100 ? dataUrl : reader.result.toString() || "")
                },
            );
            
            reader.readAsDataURL(e.target.files[0]);
            document.getElementById('upload-thumbnail').value = null;
        }
    }

    onUploadThumbnail(clip) {
        this.setState({uploadedOrGenerated: "uploading"})
        this.props.setClipForThumbnailGeneration("")
        this.props.setCroppingForClip(clip)
    }

    onClipClick(clip) {
        this.props.setActiveClip(clip)
        this.props.setActiveView(Constants.viewStates.singleClip)
    }

    onGenerateNewImage(clip) {
        this.setState({uploadedOrGenerated: "generating"})
        this.props.setClipForThumbnailGeneration(clip)
    }

    onHideMenu(projectId) {
        let projects = this.props.projects;
        let project = this.findProjectById(projects, projectId)
        project.hasMenuExpanded = false;
        this.setState({ projects: projects });
    }

    onShowMenu(projectId) {
        let projects = this.props.projects;
        let project = this.findProjectById(projects, projectId)
        project.hasMenuExpanded = true;
        this.setState({ projects: projects });
    }


renderClipRow(clip, index, projectId, cellMode) {
        return(
            <Draggable key={clip.id} draggableId={clip.id} index={index} handle=".clip-list-row">
            {(provided, snapshot) => (
                <div className="draggable-list-item">
                    <ClipListRow
                        provided={provided}
                        clip={clip}
                        deleteClip={() => this.confirmDeleteClip(clip.id)}
                        cellMode={cellMode}
                        dragging={this.state.dragging}
                        draggingId={this.state.draggingId}
                        onUploadThumbnail={() => this.onUploadThumbnail(clip)}
                        onGenerateNewImage={() => this.onGenerateNewImage(clip)}
                        renameClip={(newName) => this.renameClip(clip.id, newName)}
                        onMoveToProject={destProjectId => this.moveClipToProject(clip, projectId, destProjectId)}
                        projects={this.props.projects}
                        currentProjectId={projectId}
                        onClipClick={() => this.onClipClick(clip)}
                        orgId={this.props.activeOrganization.id}
                        onHideMenu={() => this.onHideMenu(projectId)}
                        onShowMenu={() => this.onShowMenu(projectId)}
                    />
                </div>
            )}
            </Draggable>
        );
    }

    formatTotalViews(views, classNames){
        let className = "clip-list-text";
        if (classNames){
            className = classNames;
        }

        return(
            <div className={className}>
                {views}
            </div>
        )
    }

    getSortedClips(project){
        let clips = project.clips;
        if (this.state.sortMode !== this.sortModes.none) {
            clips = clips.sort((a,b) => {

                let sortKey = "";
                if (this.state.sortMode === this.sortModes.name){
                    sortKey = "name";
                }
                else if (this.state.sortMode === this.sortModes.updated){
                    sortKey = "lastUpdated";
                }
                else if (this.state.sortMode === this.sortModes.length){
                    sortKey = "durationInSeconds";
                }
                else if (this.state.sortMode === this.sortModes.uniqueViews){
                    sortKey = "uniqueViews";
                }
                else if (this.state.sortMode === this.sortModes.totalViews){
                    sortKey = "totalViews";
                }
                else if (this.state.sortMode === this.sortModes.owner){
                    sortKey = "ownerFirstName";
                }
                else if (this.state.sortMode === this.sortModes.watched){
                    sortKey = "watched";
                }

                if (this.state.sortOrder === this.sortModes.descending){
                    if (this.state.sortMode === this.sortModes.name){
                        return b.name?.localeCompare(a.name)
                    }
                    if (a[sortKey] < b[sortKey]){
                        return 1;
                    }
                    else if (a[sortKey] > b[sortKey]){
                        return -1;
                    }
                    return 0;
                }
                else{
                    if (this.state.sortMode === this.sortModes.name){
                        return a.name?.localeCompare(b.name)
                    }
                    if (a[sortKey] > b[sortKey]){
                        return 1;
                    }
                    else if (a[sortKey] < b[sortKey]){
                        return -1;
                    }
                    return 0;
                }
            });
        }
        return clips;
    }

    getArrowForSortMode(mode) {
        if (this.state.sortMode === mode) {
            if (this.state.sortOrder === this.sortModes.ascending) {
                return (<>&#8595;</>)
            }
            return (<>&#8593;</>)
        }
    }

    convertLastUpdatedToString(lastUpdated) {
        const date = new Date(lastUpdated);
        const locale = "en-US" //TODO swap out depending on localization
        return date.toLocaleDateString(locale)
    }

    formatLastUpdated(lastUpdated, classNames){
        return(<div className={classNames}>{`${this.convertLastUpdatedToString(lastUpdated)}`}</div>)
    }

    renderClipList(projectId, cellMode) {
        let project = this.findProjectById(this.props.projects, projectId)
        if (!project) {
            return (<div />)
        }

        let clips = this.getSortedClips(project);

        let foundClip = false;
        let filter = this.state.searchFilter.toLowerCase();

        let clipList = [];
        for (let i = 0; i < clips.length; i++) {
            let clipData = [clips[i].uri.toLowerCase(),
                clips[i].name.toLowerCase(),
                this.convertLastUpdatedToString(clips[i].lastUpdated),
                this.convertDurationToString(clips[i].durationInSeconds),
                clips[i].totalViews.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                this.getOwnerNameFromClip(clips[i]).toLowerCase()
            ];

            if (clipData.some(str => str.includes(filter))) {
                foundClip = true;
                clipList.push(
                    this.renderClipRow(clips[i], i, project.id, cellMode)
                );
            }
        }

        return (
            <div className="horizontal-container full-width" style={{ justifyContent: "end", overflow: !project.hasMenuExpanded && "hidden" }} >
                <div className="vertical-container full-width">
                    {foundClip ? clipList : <div className="full-width" data-test="text-no-search-result">
                    {this.state.searchFilter !== "" && clips.length !== 0 && "No clips found with current search filter"}
                    </div>}
                    <div style={{ height: "15px" }} />
                </div>
            </div>
        );
    }

    toggleExpanded(id){
        let projects = this.props.projects;
        projects.forEach(project => {
            if (project.id === id) {
                project.expanded = !project.expanded
            }
        })
        this.setState({projects: projects});
    }

    renderProjectList(cellMode){
        //If loading show spinner below header
        if(this.state.loading && this.props.activeOrganization.name){
            return <div className="spinner" data-test="load-spinner-clips">
                <Oval
                height={100}
                width="100%"
                color="#9D8094"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#9D8094"
                strokeWidth={2}
                strokeWidthSecondary={2}
                />
            </div>    
        }

        //Else show contents of page fetched from API
        let projectList = [];

        let projects = this.props.projects

        projects.forEach(project => {
            project.sumOfViews = Object.values(project.clips).reduce((t, { totalViews }) => totalViews && totalViews !== "?" ? t + totalViews : t, 0)
            project.sumOfUniqueViews = Object.values(project.clips).reduce((t, { uniqueViews }) => uniqueViews && uniqueViews !== "?" ? t + uniqueViews : t, 0)
        })

        const defaultProject = projects.shift()
        if (this.state.sortMode === this.sortModes.totalViews) {
            projects.sort((a, b) => a.sumOfViews < b.sumOfViews ? 1 : -1)
        }
        else if (this.state.sortMode === this.sortModes.uniqueViews) {
            projects.sort((a, b) => a.sumOfUniqueViews < b.sumOfUniqueViews ? 1 : -1)
        }
        else {
            projects.sort((a, b) => a.name?.localeCompare(b.name))
        }
        projects.unshift(defaultProject)

        projects.forEach(project => {
            
            const owners = [...new Set(project.clips.map(item => item.ownerID))];
            const ownersInfo = []
            for (const ownerID of owners) {
                const ownerInfo = this.props.ownersInfo.find(user => user.id === ownerID)
                if (ownerInfo) {
                    ownersInfo.push(ownerInfo)
                }
            }
            
            let content = this.renderClipList(project.id, cellMode);

            projectList.push(
                <Droppable droppableId={String(project.id)}>
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} style={{width:"100%"}}>
                            <PortalClipListItem
                                projectID={String(project.id)}
                                projectName={project.name}
                                activeListItem={false}
                                //totalDuration={this.convertDurationToString(totalDuration)}
                                sumOfViews={this.formatTotalViews(project.sumOfViews)}
                                sumOfUniqueViews={project.sumOfUniqueViews}
                                clipNumber={project.clips.length}
                                projectOwner={project.owner}
                                expanded={project.expanded}
                                toggleExpanded={() => this.toggleExpanded(project.id)}
                                key={"PortalClipListItem" + String(project.id)}
                                itemContent={content}
                                cellMode={cellMode}
                                createNewProject={() => this.createNewProject()}
                                renameProject={(newName) => this.renameProject(project.id, project.streamApiURL, newName)}
                                deleteProject={() => this.deleteProject(project.id)}
                                placeholder={provided.placeholder}
                                dragging={this.state.dragging}
                                draggingId={this.state.draggingId}
                                ownersInfo={ownersInfo}
                                orgId={this.props.activeOrganization.id}
                                hasMenuExpanded={project.hasMenuExpanded }
                            />
                        </div>
                    )}
                </Droppable>
            );
        });

        return projectList;
    }

    getTooltipForHeaderElement(sortMode) {
        let sortKey = "";
        if (sortMode === this.sortModes.name){
            sortKey = "Name";
        }
        else if (sortMode === this.sortModes.updated){
            sortKey = "Date";
        }
        else if (sortMode === this.sortModes.length){
            sortKey = "Length";
        }
        else if (sortMode === this.sortModes.uniqueViews){
            sortKey = "Unique";
        }
        else if (sortMode === this.sortModes.totalViews){
            sortKey = "Total";
        }
        else if (sortMode === this.sortModes.watched){
            sortKey = "Watched";
        }
        return this.state.sortMode === sortMode ? "Sorted by " + sortKey : "Sort by " + sortKey
    }

    getDataTestLabelForHeaderElement(sortMode) {
        let label = "";
        if (sortMode === this.sortModes.name){
            label = "btn-sort-abc";
        }
        else if (sortMode === this.sortModes.updated){
            label = "btn-sort-date";
        }
        else if (sortMode === this.sortModes.length){
            label = "btn-sort-length";
        }
        else if (sortMode === this.sortModes.uniqueViews){
            label = "btn-sort-uniqueViews";
        }
        else if (sortMode === this.sortModes.totalViews){
            label = "btn-sort-totalViews";
        }
        else if (sortMode === this.sortModes.watched){
            label = "btn-sort-watched";
        }
        return label
    }

    renderHeaderElement(elementWidth, sortMode, iconSrc, iconSelectedSrc, iconAlt, alignment = " table-image-align-right"){
        return (
            <div style={{width: elementWidth, display: "inline-block"}}  >
                <div data-title={this.getTooltipForHeaderElement(sortMode)} data-test={this.getDataTestLabelForHeaderElement(sortMode)} className={this.state.sortMode === sortMode ? 
                    "table-header-image-container-no-hover" + alignment :
                    "table-header-image-container" + alignment
                    } onClick={() => this.setSortMode(sortMode)}>
                    <img className={this.state.sortMode === sortMode ? "full-size-image header-image-selected" : "full-size-image header-image"} src={this.state.sortMode === sortMode ? iconSelectedSrc : iconSrc} alt={iconAlt}/>    
                </div>
            </div>
        )
    }
    
    renderTableHeader(cellMode) {
        // Columns layout and width must match that of ClipListRow -> render() and PortalClipListItem -> renderListHeader
        const columnsWidth = cellMode ? Constants.columnsWidthCell : Constants.columnsWidthDesktop

        return (
            <div className="draggable-list-item full-width">    
                <div className="table-header">
                    
                    {/* 1st Column */ this.renderHeaderElement(columnsWidth[0] + "%", this.sortModes.name, nameIcon, nameIcon_selected, "name icon", " table-image-align-left")}
                    {/* 2nd Column */ this.renderHeaderElement(columnsWidth[1] + "%", this.sortModes.length, lengthIcon, lengthIcon_selected, "length icon")}
                    {/* 3rd Column */ this.renderHeaderElement(columnsWidth[2] + "%", this.sortModes.watched, watchedIcon, watchedIcon_selected, "watched icon")}
                    {/* 4th Column */ this.renderHeaderElement(columnsWidth[3] + "%", this.sortModes.uniqueViews, uniqueViewsIcon, uniqueViewsIcon_selected, "unique views icon")}
                    {/* 5th Column */ this.renderHeaderElement(columnsWidth[4] + "%", this.sortModes.totalViews, totalViewsIcon, totalViewsIcon_selected, "total views icon")}               
                    {/* 6th Column (optional) */ !cellMode && this.renderHeaderElement(columnsWidth[5] + "%", this.sortModes.updated, updatedIcon, updatedIcon_selected, "date icon")}
                    
                    {/* Last Column */}
                    <div style={{width: columnsWidth[columnsWidth.length - 1] + "%", display: "inline-block", position: "relative"}}>
                        <div className={"table-header-image-container table-image-align-right"} >
                            <img data-test="btn-search" className="full-size-image header-image" src={searchIcon} onClick={() => this.setState({searchFilter: "", searchVisible: true})} alt="search icon"/>
                        </div>
                        <div className="search-box-container horizontal-container-align-right" style={{width: this.state.searchVisible ? "250px" : "0px" }}>
                            <div className="search-box">
                                <label>
                                    <input data-test="input-search" className="search-input" value={this.state.searchFilter} name="searchInput" onChange={e => this.setState({searchFilter: e.target.value})}/>
                                </label>
                                <div data-test="btn-search-cancel" className="search-cancel" onClick={() => this.setState({searchFilter: "", searchVisible: false})}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderHeader(cellMode){
        return(
            <div>
                <div className="horizontal-container full-width">          
                    {this.renderTableHeader(cellMode)}
                </div>
            </div>
        );
    }

    renderWorkingMessage() {
        return (
            <div className="clips-working">
                <div className="spinner" data-test="load-spinner-clips-update">
                    <Oval
                    height={100}
                    width="100%"
                    color="#9D8094"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#9D8094"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                    <div> Updating Database </div>
                </div>
                
            </div>
        )
    }

    async onAcceptCrop() {

        const image = this.state.imgRef;
        
        const offscreen = new OffscreenCanvas(
          this.state.crop.width,
          this.state.crop.height,
        );
        const ctx = offscreen.getContext("2d");
        if (!ctx) {
          throw new Error("No 2d context");
        }

        ctx.drawImage(
            image, 
            this.state.crop.x, this.state.crop.y, this.state.crop.width, this.state.crop.height,
            0, 0, offscreen.width, offscreen.height,
        )
        ctx.save()

        // we might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        try {
            const blob = await offscreen.convertToBlob({
                type: "image/png",
            });
  
            let clipURI = this.props.croppingForClip.uri
            
            this.setState({ working: true})
            const res = await PictureService.changeClipPicture(blob, this.props.userInfo.id, this.props.activeOrganization.id, clipURI)
            if (res !== "File uploaded successfully") {
                alert("File upload failed! Please retry, or check your browser console for logs.")
                this.setState({ working: false })
                return
            }
            
            const res2 = await DataService.setClipThumbnailURL(clipURI, 
                                                this.props.croppingForClip.streamApiURL,
                                                PictureService.getClipThumbnailURL(clipURI),
                                                this.props.activeOrganization.id, 
                                                AuthService.getUserAccessToken(), 
                                                AuthService.getUserOpenIdAccessToken() )
            if (res2 !== "success") {   
                alert("Setting clip thumbnail failed! Please retry, or check your browser console for logs.")
                this.setState({ working: false })
                return
            }
                
            let projects = this.props.projects
            for (let i = 0; i < projects.length; i++) {
                for (let j= 0; j < projects[i].clips.length; j++) {
                    if (projects[i].clips[j].uri === clipURI) {
                        projects[i].clips[j].thumbnailURL = PictureService.getClipThumbnailURL(clipURI);
                    }
                }
            }
            this.props.setClipForThumbnailGeneration("")
            this.setState({ working: false, projects: projects})
        }
        catch (err) {
        console.error(err.name, err.message);
        }
    }

    onImageLoad(e) {
        const { naturalHeight, naturalWidth } = e.target;
        const cropSize = Math.min(Math.min(naturalHeight, naturalWidth), 200)
        const leftMargin = (naturalWidth - cropSize) / 2
        const topMargin = (naturalHeight - cropSize) / 2
        this.setState({imgRef:e.target, imgWidth : naturalWidth, imgHeight: naturalHeight, 
                        crop:{ unit: 'px', x: leftMargin, y: topMargin, width: cropSize, height: cropSize}})
    }
    
    onCancelCrop() {
        this.props.setClipForThumbnailGeneration("")
    }
    
    onCaptureAgain() {
        this.props.setThumbnailToCrop("")
    }

    renderClipCroppingContainer() {

        let containerWidth = this.props.width / 3 * 2
        let containerHeight = this.props.height / 3 * 2
        if (this.state.imgWidth) {
            containerWidth = Math.min(this.state.imgWidth + 20, containerWidth)
        }
        if (this.state.imgHeight) {
            containerHeight = Math.min(this.state.imgHeight + 100, containerHeight)
        }
        containerWidth = Math.max(containerWidth, 400)
        containerHeight = Math.max(containerHeight, 250)

        return (
            <div className="dialog-window-background-blocker">
                <div className="userImageCroppingContainer" style={{width: containerWidth, height: containerHeight}}>
                    <div style={{width:containerWidth - 20, height: containerHeight - 100, marginLeft: 10, marginTop: 10, overflow:"hidden"}}>    
                        <ReactCrop aspect={1} crop={this.state.crop} onChange={(c) => this.setState({crop:c})}>
                            <img src={this.props.thumbnailToCrop} alt="user pic cropper" onLoad={(e) => this.onImageLoad(e)}/>
                        </ReactCrop>                    
                    </div>
                    <div style={{height:25}}/>
                    <div className="full-width">
                        <div className="horizontal-container full-width center">
                            <div className="portal-button" onClick={() => this.onCancelCrop()}>CANCEL</div>
                            <div style={{width:50}}/>
                            <div className="portal-button" onClick={() => this.onAcceptCrop()}>ACCEPT</div>
                            <div style={{width:50}}/>
                            {this.state.uploadedOrGenerated === "generating" && <div className="portal-button" onClick={() => this.onCaptureAgain()}>CAPTURE AGAIN</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const viewportClassNames = `ViewportView ClipsView`
        const cellMode = this.props.width <= Constants.WIDTH_FOR_SHOWING_SIDEBAR

        return (
            <div className={viewportClassNames}>
                {this.state.working && this.renderWorkingMessage()}
            
                {this.renderHeader(cellMode)}
                <DragDropContext onDragEnd={this.onDragEnd} onBeforeDragStart={this.onBeforeDragStart}>    
                    {this.renderProjectList(cellMode)}
                </DragDropContext>

                {!this.props.activeOrganization.name && 
                "No active organization, please choose one from the user or compute panel"}

                <div style={{height: "110px"}}/>

                {this.props.thumbnailToCrop !== "" && this.renderClipCroppingContainer()}

                <input type="file" id="upload-thumbnail" accept="image/*" onChange={e => this.onSelectClipFile(e)} />
            </div>
        );
    }
}