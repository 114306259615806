import React from 'react';
import './welcome.css'
import { checkNewPassword, checkIfTenChar, checkIfOneLowercase, checkIfOneUppercase, checkIfOneDigit, checkIfOneSpecialChar } from '@/components/passwordchecker/passwordchecker';

export default class WelcomeInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {value: '', passwordVisible: false};

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        if (this.props.focusFirst){
            this.namedInput.focus();
        }
    }
    
    handleChange(event) {
        if (this.props.disabled) {
            return;
        }
        this.props.inputUpdate(event.target.value);
    }

    togglePasswordVisible() {
        this.setState ({ passwordVisible: !this.state.passwordVisible})
    }

    onKeyPressed(key) {
        if (this.props.onEnterPressed && key === "Enter") {
            this.props.onEnterPressed()
        }
    }

    render() {
        const inputType = this.props.password && !this.state.passwordVisible ? "password" : "text";
        return (
            <div className={this.props.disabled ? "login-input-disabled" : "login-input"}>
                <label>
                {this.props.label}
                </label>
                <input readOnly={this.props.disabled} data-test={this.props.label === "Email" ? "input-login" : this.props.label === "Password" ? "input-password" : ""}
                type={inputType} 
                value={this.props.inputValue} 
                onChange={this.handleChange}
                onKeyDown={e => this.onKeyPressed(e.key)}
                ref={(input) => { this.namedInput = input; }}
                />
                {this.props.password && <i className={
                    this.state.passwordVisible ? "eye-icon-for-password bi-eye" : "eye-icon-for-password bi-eye-slash"} style={{color: "black" }} onClick={() => this.togglePasswordVisible()}></i>}
                <div className="login-input-bottom-border"/>
                {this.props.label === "Choose Password" && !checkNewPassword(this.props.inputValue) && <div className="signup-input-error-message">
                    <span data-test="text-warn-length" className={checkIfTenChar(this.props.inputValue) ? "" : "red-text"}>length 10</span>, 
                    <span data-test="text-warn-upper" className={checkIfOneUppercase(this.props.inputValue) ? "" : "red-text"}> upper</span> & 
                    <span data-test="text-warn-lower" className={checkIfOneLowercase(this.props.inputValue) ? "" : "red-text"}> lower</span> case, 
                    <span data-test="text-warn-num" className={checkIfOneDigit(this.props.inputValue) ? "" : "red-text"}> number</span> & 
                    <span data-test="text-warn-special" className={checkIfOneSpecialChar(this.props.inputValue) ? "" : "red-text"}> special </span> char</div>}
                {this.props.label === "Confirm Password" && !this.props.passwordsMatch && <div data-test="text-warn-match" className="signup-input-error-message red-text"> Don't match </div>}
            </div>
        );
    }
}