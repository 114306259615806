import React from 'react';

import './welcome.css'

import holoSuiteImage from "@/images/welcome/FullLogo_White.svg";

import WelcomeInput from './welcomeinput';

import { AuthService } from '@/services/authservice';

import { checkNewPassword } from '@/components/passwordchecker/passwordchecker';

export default class ForgotPassSidebar extends React.Component {
    constructor(props) {
        super(props);

        const params = new URL(window.location.href).searchParams;

        this.state = {
            canResetPass: false,
            email: params.get('email'),
            password: "",
            confirmPassword: "",
            token: params.get('passToken'),
            tokenValid: "not checked"
        };
    }

    renderWelcomeContent() {
        return (
            <>
                <div className="normal-text login-welcome-text">
                    Welcome back! You can reset your password below. If you have any trouble contact us at
                    <a className="login-link" href="mailto:support@arcturus.studio">support@arcturus.studio</a>
                </div>
                <div style={{ "minHeight": "23px" }} />
            </>
        );
    }

    validateKeys() {
        const validateString = function (value) {
            return value !== null && value !== undefined && value.length > 0;
        }

        let success =
            validateString(this.state.email) &&
            validateString(this.state.password) &&
            checkNewPassword(this.state.password) &&
            this.passwordsMatch()
        return success
    }

    checkIfCanResetPass() {
        if (this.validateKeys()) {
            this.setState({ canResetPass: true })
        }
        else {
            this.setState({ canResetPass: false })
        }
    }

    updateInput(key, newValue) {
        const state = this.state;
        state[key] = newValue
        this.setState(state)

        this.checkIfCanResetPass();
    }

    passwordsMatch() {
        return this.state.password === this.state.confirmPassword
    }

    renderFormContent() {
        return (
            <div className="login-content">

                <div className="login-help-error" />
                <WelcomeInput disabled
                    label="Email (user name)"
                    inputUpdate={(value) => this.updateInput("email", value)}
                    inputValue={this.state.email}

                />
                <div className="login-help-error" />
                <WelcomeInput
                    label="Choose Password"
                    password={true}
                    inputUpdate={(value) => this.updateInput("password", value)}
                    inputValue={this.state.password}

                />
                <div className="login-help-error" />
                <WelcomeInput
                    label="Confirm Password"
                    password={true}
                    inputUpdate={(value) => this.updateInput("confirmPassword", value)}
                    inputValue={this.state.confirmPassword}
                    passwordsMatch={this.passwordsMatch()}
                />
            </div>
        )
    }

    async submitForm() {

        // prevent multiple clicks
        if (this.state.working) {
            return
        }
        this.setState({ working: true })

        const resetResult = await AuthService.resetUserPassword(this.state.token,
            this.state.email,
            this.state.password)
        if (resetResult === "success") {
            alert("Password was reset successfully. You can use your new password to sign in")
            window.location.href = '/';
        }

    }

    renderForgotPassButton() {
        let buttonClass = "login-confirm-button";
        let onClick = () => { };
        if (!this.state.canResetPass) {
            buttonClass = "login-confirm-button-disabled";
        }
        else {
            onClick = () => this.submitForm()
        }

        return (
            <div className="login-content" style={{ "marginTop": "33px" }}>
                <div className={this.state.working ? "login-confirm-button-disabled" : buttonClass}>
                    <div className="login-confirm-button-text" onClick={onClick}>
                        CHANGE PASSWORD
                    </div>
                </div>
            </div>
        )
    }

    renderFooter() {
        return (
            <div className="login-footer-container">
                <img src={holoSuiteImage} className="login-footer-image" alt={"login footer"} />
            </div>
        )
    }

    render() {
        if (this.state.tokenValid === "not checked") {
            this.state.tokenValid = "checking"
            AuthService.checkPassToken(this.state.email, this.state.token).then(checkPassMessage => {
                if (checkPassMessage === "success") {
                    this.setState({ tokenValid: true })
                }
                else {
                    window.location.href = '/';
                }
            })
        }

        return (
            <div className="sidebar login-sidebar">
                {this.renderWelcomeContent()}
                {this.renderFormContent()}
                {this.renderForgotPassButton()}
                {this.renderFooter()}
            </div>
        );
    }
}