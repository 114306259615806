import React from 'react'
import './welcome.css'

import LoginSidebar from "./loginsidebar"
import welcomeImg from "@/images/welcome/welcome.svg"

export default class LoginPage extends React.Component {
    render() {

        const viewportClassNames = `login-page`
        return (
            <div className={viewportClassNames}>
                <LoginSidebar
                authenticate={this.props.authenticate}
                signInFailed={this.props.signInFailed}
                />
                <div className="main-brand">
                    <img className="welcome-to-img" src={welcomeImg} />
                </div>
            </div>
        );
    }
}