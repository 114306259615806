import React from 'react';

import './welcome.css'

import holoSuiteImage from "@/images/welcome/FullLogo_White.svg";

import WelcomeInput from './welcomeinput';

import { AuthService } from '@/services/authservice';

import { checkNewPassword } from '@/components/passwordchecker/passwordchecker';

export default class SignupSidebar extends React.Component {
    constructor(props) {
        super(props);

        const params = new URL(window.location.href).searchParams;

        this.state = {
            canCreateAccount: false,
            firstName: params.get('firstName'),
            lastName: params.get('lastName'),
            email: params.get('email'),
            password: "",
            confirmPassword: "",
            acceptedTermsAndConditions: false,
            token: params.get('invToken'),
            orgId: params.get('orgId'),
            invitationId: params.get('invId'),
            invitationValid: "not checked"
        };
    }

    toggleSignInView = () => {
        this.props.toggleSignInView()
    }

    renderWelcomeContent() {
        return (
            <>
                <div className="normal-text login-welcome-text">
                    Welcome to HoloSuite! To create your account please fill in your information below. If you have any trouble contact us at <a className="login-link" href="mailto:support@arcturus.studio">support@arcturus.studio</a>
                </div>
                <div style={{ "minHeight": "23px" }} />
            </>
        );
    }

    validateKeys() {
        const validateString = function (value) {
            return value !== null && value !== undefined && value.length > 0;
        }

        let success =
            validateString(this.state.firstName) &&
            validateString(this.state.lastName) &&
            validateString(this.state.email) &&
            validateString(this.state.password) &&
            checkNewPassword(this.state.password) &&
            this.passwordsMatch() &&
            this.state.acceptedTermsAndConditions;
        return success
    }

    checkIfCanOpenAccount() {
        if (this.validateKeys()) {
            this.setState({ canCreateAccount: true })
        }
        else {
            this.setState({ canCreateAccount: false })
        }
    }

    updateInput(key, newValue) {
        const state = this.state;
        state[key] = newValue
        this.setState(state)

        this.checkIfCanOpenAccount();
    }

    passwordsMatch() {
        return this.state.password === this.state.confirmPassword
    }

    renderFormContent() {
        return (
            <div className="login-content">
                <WelcomeInput
                    label="First Name"
                    focusFirst={true}
                    inputUpdate={(value) => this.updateInput("firstName", value)}
                    inputValue={this.state.firstName}

                />
                <div className="login-help-error" />
                <WelcomeInput
                    label="Last Name"
                    inputUpdate={(value) => this.updateInput("lastName", value)}
                    inputValue={this.state.lastName}

                />
                <div className="login-help-error" />
                <WelcomeInput disabled
                    label="Email (user name)"
                    inputUpdate={(value) => this.updateInput("email", value)}
                    inputValue={this.state.email}

                />
                <div className="login-help-error" />
                <WelcomeInput
                    label="Choose Password"
                    password={true}
                    inputUpdate={(value) => this.updateInput("password", value)}
                    inputValue={this.state.password}

                />
                <div className="login-help-error" />
                <WelcomeInput
                    label="Confirm Password"
                    password={true}
                    inputUpdate={(value) => this.updateInput("confirmPassword", value)}
                    inputValue={this.state.confirmPassword}
                    passwordsMatch={this.passwordsMatch()}
                />
            </div>
        )
    }

    renderTermsAndConditions() {
        return (
            <div className="login-content vertical-container">

                <div style={{ "minHeight": "23px" }} />

                <div className="normal-text horizontal-container">
                    <input type="checkbox" className="checkbox" onChange={event => this.updateInput("acceptedTermsAndConditions", event.target.checked)} />
                    <div className="full-width horizontal-container">
                        <div>
                            I've read and accept the <a className="login-link" href="https://www.datocms-assets.com/104353/1694523930-general-terms-of-use.pdf" target="_blank" rel="noreferrer">
                                terms and conditions
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    async submitForm() {

        // prevent multiple clicks
        if (this.state.working) {
            return
        }
        this.setState({ working: true })

        const acceptResult = await AuthService.acceptInvite(this.state.token,
            this.state.invitationId,
            this.state.email,
            this.state.lastName,
            this.state.firstName,
            this.state.password)
        if (acceptResult !== "success") {
            alert(acceptResult)
            window.location.href = '/';
        }

        alert("Account created successfully. You can use your new credentials to sign in")
        window.location.href = '/';
    }

    renderOpenAccountButton() {
        let buttonClass = "login-confirm-button";
        let onClick = () => { };
        if (!this.state.canCreateAccount || !(this.state.invitationValid === true)) {
            buttonClass = "login-confirm-button-disabled";
        }
        else {
            onClick = () => this.submitForm()
        }

        return (
            <div className="login-content" style={{ "marginTop": "33px" }}>
                <div className={buttonClass} onClick={onClick}>
                    <div className="login-confirm-button-text">
                        OPEN ACCOUNT
                    </div>
                </div>
            </div>
        )
    }

    renderFooter() {
        return (
            <div className="login-footer-container">
                <img src={holoSuiteImage} className="login-footer-image" alt={"login footer"} />
            </div>
        )
    }

    render() {
        if (this.state.invitationValid === "not checked") {
            AuthService.checkInvite(this.state.invitationId, this.state.email).then(checkInviteMessage => {
                if (checkInviteMessage !== "success") {
                    alert(checkInviteMessage)
                    window.location.href = '/';
                }
                this.setState({ invitationValid: true })
            })
        }

        return (
            <div className="sidebar login-sidebar sign-up-sidebar">
                {this.renderWelcomeContent()}
                {this.renderFormContent()}
                {this.renderTermsAndConditions()}
                {this.renderOpenAccountButton()}
                {this.renderFooter()}
            </div>
        );
    }
}