import React from 'react';
import * as Constants from '@/constants'

import './portallistitem.css'

import triangleIcon from "@/images/viewport/triangleIcon.svg"
import triangleIconFilled from "@/images/viewport/triangleIconFilled.svg"

import UserLogo from "@/components/userlogo/userlogo"
import OrgLogo from "@/components/orglogo/orglogo"
import { PictureService } from '@/services/pictureservice';

import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css'

export default class PortalListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statusHovered: false,
            imgUpdater: 0,
            uploadedImgSrc: "",
            uploadedImgWidth: "",
            uploadedImgHeight: "",
            uploadedImgRef: "",
            previewCanvasRef: "",
            uploadingUserOrOrg: ""
        }
    }

    onSelectUserFile(e) {
        this.state.uploadingUserOrOrg = "user"
        this.onSelectFile(e)
    }

    onSelectOrgFile(e) {
        this.state.uploadingUserOrOrg = "org"
        this.onSelectFile(e)
    }


    onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", async () =>
            {
                var image = new Image();
                image.src = reader.result;
                await image.decode();
                    
                // Resize the image if needed
                var canvas = document.createElement('canvas'),
                    max_width = this.props.width / 3 * 2 - 20,
                    max_height = this.props.height / 3 * 2 - 100,
                    width = image.width,
                    height = image.height;

                if (width > max_width) {
                    height *= max_width / width;
                    width = max_width;
                }
                if (height > max_height) {
                    width *= max_height / height;
                    height = max_height;
                }
                
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                const dataUrl = canvas.toDataURL('image/png');

                this.setState({uploadedImgSrc : dataUrl.length > 100 ? dataUrl : reader.result?.toString() || ""})},
            );
            
            reader.readAsDataURL(e.target.files[0]);
            if (this.state.uploadingUserOrOrg === "org") {
                document.getElementById("upload-photo" + this.props.organization.id).value = null;
            }
            else {
                document.getElementById("upload-user-photo").value = null;
            }
            
        }
    }

    onImageLoad(e) {
        const { naturalHeight, naturalWidth } = e.target;
        const cropSize = Math.min(Math.min(naturalHeight, naturalWidth), 200)
        const leftMargin = (naturalWidth - cropSize) / 2
        const topMargin = (naturalHeight - cropSize) / 2
        this.setState({uploadedImgRef:e.target, uploadedImgWidth : naturalWidth, uploadedImgHeight: naturalHeight, 
                        crop:{ unit: 'px', x: leftMargin, y: topMargin, width: cropSize, height: cropSize}})
    }

    async onDownloadCropClick() {
        const image = this.state.uploadedImgRef;
        
        const offscreen = new OffscreenCanvas(
          this.state.crop.width,
          this.state.crop.height,
        );
        const ctx = offscreen.getContext("2d");
        if (!ctx) {
          throw new Error("No 2d context");
        }

        ctx.drawImage(
            image, 
            this.state.crop.x, this.state.crop.y, this.state.crop.width, this.state.crop.height,
            0, 0, offscreen.width, offscreen.height,
        )
        ctx.save()

        // we might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        try {
            const blob = await offscreen.convertToBlob({
                type: "image/png",
            });
            if (this.state.uploadingUserOrOrg === "user") {
                const res = await PictureService.changeUserPicture(blob, this.props.userID, this.props.organization.id)
                if (res !== "File uploaded successfully") {
                    alert("File upload failed! Please retry, or check your browser console for logs.")
                }
            }
            else if (this.state.uploadingUserOrOrg === "org") {
                const res = await PictureService.changeOrgPicture(blob, this.props.userID, this.props.organization.id)
                this.props.setOrgHasCustomPicture(this.props.organization.id, true)
                if (res !== "File uploaded successfully") {
                    alert("File upload failed! Please retry, or check your browser console for logs.")
                }
            }
            
        } catch {
            throw new Error("Error creating blob for cropped image");
        }
        
        this.cancelImage();
        // this updates user image in current page and in sidebar
        this.setState({imgUpdater: this.state.imgUpdater+1})
        this.props.refreshSidebarPicture()
    }

    renderListHeader(){

        let toggleExpanded = () => this.props.toggleExpanded(this.props.organization);
        let setAsActive = () => this.props.setActiveOrganization(this.props.organization);
        let statusText = "SWITCH";
        let statusTextClass = "status-text status-text-not-current";
        if (this.props.activeListItem){
            statusText = "CURRENT";
            statusTextClass = "status-text-current status-text";
        }

        return(
            <div className="portal-list-item-header">
                <div className="horizontal-container center-vert justify-left full-height" style={{width: "50%"}}>
                    <div style={{marginLeft:"10px"}}>
                    
                    <div style={{marginTop:"2px"}} className={this.props.expanded ? "triangle" : "triangle rotated"} data-test="btn-exp-tri" onClick={toggleExpanded}>
                        <img src={triangleIcon} alt="triangle icon"></img>
                        <img src={triangleIconFilled} className="filled-triangle" alt="triangle icon"></img>
                    </div>
                    
                    </div>
                    <div className="horizontal-container full-height">  
                        <span className="image-container" style={{paddingRight: "20px"}}>
                            <OrgLogo 
                                orgID = {this.props.organization.id} 
                                imgUpdater = {this.state.imgUpdater}
                                hasCustomPicture = {this.props.organization.hasCustomPicture}
                                setOrgHasCustomPicture = {(organization, value) => this.props.setOrgHasCustomPicture(organization, value)}
                                />
                            {this.props.arcturusUser && this.props.organization.id !== Constants.arcturusOrgId && <div className="org-image-selector image-selector portal-button" data-test="btn-upload-img-org">
                                <label htmlFor={"upload-photo" + this.props.organization.id}>UPLOAD NEW IMAGE</label>
                                <input type="file" id={"upload-photo" + this.props.organization.id} accept="image/*" onChange={e => this.onSelectOrgFile(e)} />
                            </div>} 
                        </span>
                        <span className="portal-list-item-title align-items" data-test="card-org-title">
                            {this.props.organization.name}
                        </span>
                    </div>
                </div>
                
                <div className="horizontal-container-align-left full-height" style={{width: "40%"}}>
                    {this.props.activeListItem ? 
                        <div className={statusTextClass}>
                            {statusText}
                        </div>
                    :
                        <div className={statusTextClass} data-test="btn-switch" onClick={setAsActive} onMouseEnter={() => this.setState ({statusHovered: true})}
                        onMouseLeave={() => this.setState ({statusHovered: false})}>
                            {this.state.statusHovered ? statusText === "SWITCH" ? "MAKE CURRENT" : statusText : statusText}
                        </div>
                    }

                </div>

                <div className="image-container user-logo-container">
                    <div style={{marginTop: "8px"}}>
                        <UserLogo  
                            userID = {this.props.userID} 
                            orgID = {this.props.organization.id} 
                            userInfo = {this.props.userInfo} 
                            imgUpdater = {this.state.imgUpdater}
                            onlyInitials = { this.props.arcturusUser && this.props.organization.id !== Constants.arcturusOrgId && !this.props.activeListItem}
                            />
                        {this.props.activeListItem && <div className="user-image-selector image-selector portal-button" data-test="btn-upload-img-user">
                            <label htmlFor="upload-user-photo">UPLOAD NEW IMAGE</label>
                            <input type="file" id="upload-user-photo" accept="image/*" onChange={e => this.onSelectUserFile(e)} />
                        </div>}
                    </div>
                </div>
            </div>
        );
    }

    // this hides upload panel
    cancelImage(){
        this.setState({uploadedImgSrc:"", uploadedImgWidth : "", uploadedImgHeight: "", uploadingUserOrOrg: ""})
    }

    render() {

        let containerWidth = this.props.width / 3 * 2
        let containerHeight = this.props.height / 3 * 2
        if (this.state.uploadedImgWidth) {
            containerWidth = Math.min(this.state.uploadedImgWidth + 20, containerWidth)
        }
        if (this.state.uploadedImgHeight) {
            containerHeight = Math.min(this.state.uploadedImgHeight + 100, containerHeight)
        }
        containerWidth = Math.max(containerWidth, 400)
        containerHeight = Math.max(containerHeight, 250)

        return ( 
            <div data-test="card-organization">
                <div className="portal-list-item" data-test={this.props.activeListItem ? "card-org-current" : ""}>
                    {this.renderListHeader()}

                    <div id="content" data-test="card-org-details" className={this.props.expanded ? "portal-list-item-content user-content expanded" : "user-content"}>
                        <div className="inside inside-portal-list-item">
                            {this.props.itemContent}
                        </div>
                    </div>

                    {!!this.state.uploadedImgSrc && 
                        <div className="dialog-window-background-blocker">
                            <div className="userImageCroppingContainer" style={{width: containerWidth, height: containerHeight}}>
                                <div style={{width:containerWidth - 20, height: containerHeight - 100, marginLeft: 10, marginTop: 10, overflow:"hidden"}}>
                                    <ReactCrop circularCrop={this.state.uploadingUserOrOrg === "user"} aspect={1} crop={this.state.crop} onChange={(c) => this.setState({crop:c})}>
                                        <img src={this.state.uploadedImgSrc} alt="user pic cropper" onLoad={(e) => this.onImageLoad(e)}/>
                                    </ReactCrop>                    
                                </div>
                                <div style={{height:25}}/>
                                <div className="full-width">
                                    <div className="horizontal-container full-width center">
                                        <div className="portal-button" onClick={() => this.onDownloadCropClick()}> UPLOAD IMAGE </div>
                                        <div style={{width:50}}/>
                                        <div className="portal-button" onClick={() => this.cancelImage()}> CANCEL IMAGE </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                  
                </div>
            </div>
            )
    }
}