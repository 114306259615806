import React from 'react'
import { Oval } from 'react-loader-spinner'

import {
    FlexibleXYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    LineMarkSeries
} from 'react-vis';

import * as Constants from '@/constants'
import './computeview.css'

import UserLogo from "@/components/userlogo/userlogo"
import OrgLogo from "@/components/orglogo/orglogo"


import computeIcon from "@/images/viewport/computeview/Compute_icon.svg";
import viewsIcon from "@/images/viewport/computeview/Views_icon.svg";
import consumptionIcon from "@/images/viewport/computeview/consumption.svg";
import usageIcon from "@/images/viewport/computeview/usage.svg";


import { ComputeService } from '@/services/computeservice';

export default class ComputeView extends React.Component {

    constructor(props) {
        super(props);

        document.title = "Compute - HoloSuite Portal"

        this.state = {
            computeUsage: this.props.computeUsage,
            computeEntitlement: this.props.computeEntitlement,
            viewUsage: this.props.orgViews.reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0),
            viewEntitlement: this.props.viewEntitlement,
            startMonth: 5,
            endMonth: 0,
            labels: ""
        }

        this.onStartChange = this.onStartChange.bind(this);
        this.onEndChange = this.onEndChange.bind(this);


        this.months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        this.currentMonth = new Date().getMonth()

        if (this.props.activeOrganization.name && this.state.computeUsage === "") {
            this.state.computeUsage = "loading"
            this.state.computeEntitlement = "loading"

            ComputeService.getComputeUsage(this.props.activeOrganization.name).then(async response => {
                if (!response) {
                    alert("Error retrieving organization usage, please see console for details")
                    response = [0,0,0,0,0,0]
                }
                this.state.computeUsage = response
                this.props.setComputeUsage(response)

                let numDataPoints = Math.max(this.state.computeUsage.length, this.props.orgViews.length)
                let labels = []
                let date = new Date()
                for (let i = 0; i < numDataPoints; i++) {
                    let year = date.getFullYear() % 100
                    let month = this.months[date.getMonth()]
                    date.setMonth(date.getMonth() - 1)
                    labels.push(month + " " + year)
                }
                this.state.labels = labels

                this.forceUpdate()
            })

            ComputeService.getEntitlement(this.props.activeOrganization.id).then(async response => {

                if (!response || !response.compute || !response.view) {
                    alert("Error retrieving organization entitlement, please see console for details")
                    this.state.computeEntitlement = {balance: 0, activeLicenses: 0}
                    this.props.setComputeEntitlement( {balance: 0, activeLicenses: 0} )
                    this.state.viewEntitlement = {balance: 0, activeLicenses: 0}
                    this.props.setViewEntitlement( {balance: 0, activeLicenses: 0} )
                }
                else {
                    this.state.computeEntitlement = response.compute
                    this.props.setComputeEntitlement(response.compute)
                    this.state.viewEntitlement = response.view
                    this.props.setViewEntitlement(response.view)
                }
                this.forceUpdate()
            })
        }
    }

    shouldShow(i, numDataPoints) {
        if (numDataPoints <= 8) {
            return true
        }
        if (numDataPoints <= 16) {
            return i % 2 === 0
        }
        if (numDataPoints <= 24) {
            return i % 4 === 0
        }
        return i % 6 === 0
    }

    renderLineView(color, strokeStyle, strokeWidth, fill, size, data, showGrids, showAxis, key, top, left) {
        let gridElements = [];
        let axisElements = [];

        if (showGrids) {
            gridElements = [
                <HorizontalGridLines tickTotal={3} style={{ stroke: "#B7B4C3" }} key={key + "horizontalGrid"} />,
                <VerticalGridLines tickTotal={6} style={{ stroke: "#B7B4C3" }} key={key + "verticalGrid"} />
            ]
        }

        const maxValue = Math.max(Math.ceil(Math.max(...data.map(o => o.y))), 1);
        const yAxisValues = [
            0,
            maxValue / 2.0,
            maxValue
        ]
        let xAxisValues = []
        let dataPoints = this.state.startMonth - this.state.endMonth + 1
        for (let i = 0; i < dataPoints; i++) {
            xAxisValues.push(i);
        }

        if (showAxis) {
            axisElements = [
                <XAxis
                    key={key + "xAxis"}
                    tickValues={xAxisValues}
                    tickFormat={i => this.shouldShow(i, dataPoints) && this.state.labels[this.state.startMonth - i]}
                    hideLine
                    tickSize={0}
                    top={307}
                    style={{
                        ticks: { fill: "#B7B4C3" },
                    }}
                />,
                <YAxis
                    tickSize={0}
                    tickValues={yAxisValues}
                    key={key + "yAxis"}
                    top={top}
                    style={{
                        ticks: { fill: color },
                    }}
                    hideLine
                />
            ];
        }
        else {
            axisElements = [
                <YAxis
                    tickSize={0}
                    tickValues={yAxisValues}
                    key={key + "yAxis"}
                    top={top}
                    style={{
                        ticks: { fill: color },
                    }}
                    hideLine
                />,
            ];
        }

        return (
            <div data-test="graph-usageRate" className="overlay-graph compute-overlay-graph" key={key}>
                <FlexibleXYPlot yDomain={[0, maxValue]}>
                    {gridElements}
                    <LineMarkSeries
                        data={data}
                        color={color}
                        fill={fill}
                        size={size}
                        strokeStyle={strokeStyle}
                        strokeWidth={strokeWidth}
                    />
                    {axisElements}
                </FlexibleXYPlot>
            </div>
        );
    }

    getComputeValues() {
        let values = []
        const start = this.state.startMonth
        const end = this.state.endMonth
        for (let i = start; i >= end; i--) {
            values.push({ x: start - i, y: this.state.computeUsage[i] ? this.state.computeUsage[i] : 0 })
        }
        return values;
    }

    getViewsValues() {
        let values = []
        const start = this.state.startMonth
        const end = this.state.endMonth
        for (let i = start; i >= end; i--) {
            values.push({ x: start - i, y: this.props.orgViews[i] ? this.props.orgViews[i] : 0 })
        }
        return values;
    }

    computeLineView() {
        return (
            this.renderLineView("#B843EE", "solid", 4, "#B7B4C3", "3", this.getComputeValues(), true, true, "graph1", 6, 0)
        );
    }

    viewsLineView() {
        return (
            this.renderLineView("#00C7E2", "solid", 4, "#B7B4C3", "3", this.getViewsValues(), false, false, "graph2", 16, 0)
        );
    }

    renderUsageGraph() {
        return (
            <div data-test="card-usage-graph" className="graph-container compute-graph-container">
                <div className="vertical-container full-width">
                    {this.renderComputeGraphHeader()}
                    {this.computeLineView()}
                    {this.viewsLineView()}
                </div>
            </div>
        );
    }

    renderComputeGraph() {
        return (
            <div className="horizontal-container hidden-scroll full-width" style={{ justifyContent: "flex-start", alignItems: "top" }}>
                {this.renderUsageGraph()}
            </div>
        )
    }

    renderComputeBars() {
        const balance = this.state.computeEntitlement.balance
        const activeLicenses = this.state.computeEntitlement.activeLicenses

        const use = this.state.computeEntitlement.activeLicenses - this.state.computeEntitlement.balance

        const viewActiveLicenses = this.state.viewEntitlement.activeLicenses
        const viewUsage = this.state.viewUsage
        const viewBalance = viewActiveLicenses - viewUsage


        return (
            <div className="horizontal-container hidden-scroll full-width" style={{ justifyContent: "flex-start", paddingLeft: "20px", alignItems: "top" }}>
                <div data-test="card-compute-graph" className="graph-container compute-graph-container" style={{ height: this.props.width > Constants.WIDTH_FOR_SHOWING_SIDEBAR ? "" : "275px" }} >
                    <div className="vertical-container full-width">
                        {this.renderComputeBarsHeader()}

                        <div className="vertical-container full-width" >

                            <div style={{ height: "20px" }} />

                            <div className="horizontal-container full-width center compute-text compute-text-color">
                                <img src={computeIcon} alt="compute hours" />
                                <div style={{ width: "10px" }} />
                                COMPUTE
                                <div style={{ width: "3px" }} />
                                <div className="compute-small-text"> HR</div>
                            </div>

                            <div className="horizontal-container center center-vert bar-container">
                                <div data-test="field-compute-use" className="compute-text usage-number compute-text-color">
                                    {Math.round(use / 3600)}
                                </div>
                                <div data-test="graph-compute-bar" className="bar compute-bar">
                                    {balance < 0 || balance > activeLicenses || !(activeLicenses > 0) ?
                                        <div>   </div>
                                        :
                                        <span data-test="graph-compute-fill" style={{ width: String((activeLicenses - balance) / activeLicenses * 100) + "%" }}></span>
                                    }
                                </div>
                                <div data-test="field-compute-total" className="compute-text total-number">
                                    {Math.round(activeLicenses / 3600)}
                                </div>
                            </div>

                            <div style={{ height: "20px" }} />

                            <div className="horizontal-container full-width center compute-text view-text-color">
                                <img src={viewsIcon} alt="view minutes" />
                                <div style={{ width: "10px" }} />
                                VIEW
                                <div style={{ width: "3px" }} />
                                <div className="compute-small-text"> MN</div>
                            </div>


                            <div className="horizontal-container center center-vert bar-container">
                                <div data-test="field-viewMin-use" className="compute-text usage-number view-text-color">
                                    {Math.round(viewUsage)}
                                </div>
                                <div data-test="graph-viewMin-bar" class="bar view-bar">
                                    {viewBalance < 0 || viewBalance > viewActiveLicenses || !(viewActiveLicenses > 0) ?
                                        <div>   </div>
                                        :
                                        <span data-test="graph-viewMin-fill" style={{ width: String((viewActiveLicenses - viewBalance) / viewActiveLicenses * 100) + "%" }}></span>
                                    }
                                </div>
                                <div data-test="field-viewMin-total" className="compute-text total-number">
                                    {Math.round(viewActiveLicenses / 60)}
                                </div>
                            </div>

                            <div className="horizontal-container center-vert usage-total-labels">
                                <div className="usage"> USAGE </div>
                                <div className="total"> TOTAL </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMonthOption(value, label, disable, selected) {
        if (disable) {
            return (
                <option data-test="month-disabled" value={value} disabled> {label} </option>
            )
        }
        if (selected) {
            return (
                <option data-test="month-selected" value={value} className="option-enabled" selected> {label} </option>
            )
        }

        return (
            <option data-test="month-enabled" className="option-enabled" value={value}> {label} </option>
        )
    }

    onStartChange(event) {
        this.setState({ startMonth: Number(event.target.value) });
    }

    onEndChange(event) {
        this.setState({ endMonth: Number(event.target.value) });
    }

    renderMonthDropdowns() {

        let beginOptions = []
        let endOptions = []
        const labels = this.state.labels
        const currentBegin = this.state.startMonth
        const currentEnd = this.state.endMonth
        for (let i = 0; i < labels.length; i++) {
            beginOptions.push(this.renderMonthOption(i, labels[i], i <= currentEnd, i === currentBegin))
            endOptions.push(this.renderMonthOption(i, labels[i], i >= currentBegin, i === currentEnd))
        }

        return (
            <div className="horizontal-container">
                <select data-test="drop-month-start" onChange={this.onStartChange}>
                    {beginOptions}
                </select>
                <div style={{ width: 2 }} />
                -
                <div style={{ width: 5 }} />
                <select data-test="drop-month-end" onChange={this.onEndChange}>
                    {endOptions}
                </select>
            </div>
        )
    }


    renderComputeGraphHeader() {
        return (
            <div className="portal-list-item-header">
                <div className="horizontal-container clip-list-text">
                    <div style={{ width: 10 }} />
                    <img src={usageIcon} alt="USAGE RATE" />
                    <div style={{ width: 5 }} />
                    USAGE RATE
                </div>

                <div className="horizontal-container">
                    <div data-title="Compute Hours">
                        <img src={computeIcon} alt="clip total views" />
                    </div>
                    <div style={{ width: "8px" }} />
                    <div data-title="View Minutes" style={{ paddingBottom: "4px" }}>
                        <img src={viewsIcon} alt="view minutes" />
                    </div>


                </div>

                <div className="horizontal-container clip-list-text">
                    {this.renderMonthDropdowns()}
                    <div style={{ width: 10 }} />
                </div>
            </div>
        )
    }

    renderComputeBarsHeader() {
        return (
            <div className="portal-list-item-header">
                <div className="horizontal-container clip-list-text">
                    <div style={{ width: 10 }} />
                    <img src={consumptionIcon} alt="COMPUTE CONSUMPTION" />
                    <div style={{ width: 5 }} />
                    COMPUTE CONSUMPTION
                </div>
            </div>
        )
    }

    render() {
        const viewportClassNames = `ViewportView ComputeView`;

        //If loading show spinner
        if (this.state.computeUsage === "loading" || this.state.computeEntitlement === "loading") {
            return <div className="spinner" data-test="load-spinner-compute">
                <Oval
                    height={100}
                    width="100%"
                    color="#9D8094"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#9D8094"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        }

        const cellMode = this.props.width > Constants.WIDTH_FOR_SHOWING_SIDEBAR

        return (
            <div className={viewportClassNames}>

                <div style={{ height: "54px" }} />

                <div className="portal-list-item">

                    <div className="portal-list-item-header">
                        <div className="horizontal-container center-vert justify-left full-height" style={{ width: "50%" }}>

                            <div className="horizontal-container full-height" style={{ paddingLeft: "20px" }}>
                                <span className="image-container" style={{ paddingRight: "20px" }}>
                                    <OrgLogo
                                        orgID={this.props.activeOrganization.id}
                                        imgUpdater={this.state.imgUpdater}
                                        hasCustomPicture={this.props.activeOrganization.hasCustomPicture}
                                    />
                                </span>
                                <span className="portal-list-item-title align-items">
                                    {this.props.activeOrganization.name} resource usage
                                </span>
                            </div>
                        </div>

                        <div className="image-container user-logo-container">
                            <div style={{ marginTop: "8px" }}>
                                <UserLogo
                                    userID={this.props.userInfo ? this.props.userInfo.id : ""}
                                    orgID={this.props.activeOrganization.id}
                                    userInfo={this.props.userInfo}
                                />
                            </div>
                        </div>
                    </div>

                    <div data-test="container-compute-graphs" className={cellMode ? "horizontal-container full-width" : "vertical-container full-width"}>
                        <div id="content" className={"portal-list-item-content expanded"} style={{ width: cellMode ? "45%" : "100%" }} >
                            <div className="inside inside-portal-list-item">
                                {this.renderComputeBars()}
                            </div>
                        </div>

                        <div id="content" className={"portal-list-item-content expanded"} style={{
                            width: cellMode ? "55%" : this.props.width - 60 + "px",
                            marginLeft: cellMode ? "0" : "20px"
                        }}>
                            <div className="inside inside-portal-list-item">
                                {this.renderComputeGraph()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}