import { AuthService } from '@/services/authservice';

export function checkNewPassword (pass) {
    if (pass === "" || pass === undefined || pass === AuthService.getPassword()) {
        return true;
    }
    return checkIfTenChar(pass) &&  
            checkIfOneLowercase(pass) &&  
            checkIfOneUppercase(pass) &&  
            checkIfOneDigit(pass) &&  
            checkIfOneSpecialChar(pass)
}

export function checkIfTenChar (text) {
    return text?.length >= 10;
}
    
export function checkIfOneLowercase (text) {
    return /[a-z]/.test(text);
}
  
export function checkIfOneUppercase (text) {
    return /[A-Z]/.test(text);
}
  
export function checkIfOneDigit (text) {
    return /[0-9]/.test(text);
}
  
export function checkIfOneSpecialChar (text) {
    return /[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(text);
}