import React from 'react';
import * as Constants from '@/constants'
import './cliplistrow.css'

import MoveClipMenu from "./moveclipmenu/moveclipmenu"
import ClipThumbnail from "@/viewport/components/clipthumbnail/clipthumbnail"
import UserLogo from "@/components/userlogo/userlogo"

import MoveToClipMenuIcon from "@/images/viewport/clipsview/cliplistrow/MoveToClipMenuIcon.svg"


export default class ClipListRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menuExpanded: false, renaming: false}
    }
    
    
    selectElementContents(el) {
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    processRenameClipClick() {
        this.setState({renaming: true});
        let el = document.getElementById(this.props.clip.id);
        el.focus()
        this.selectElementContents(el);
    }
    
    onKeyPressed(key) {
        if (this.state.renaming && key === "Escape") {
            this.setState({renaming: false});
            document.getElementById(this.props.clip.id).innerHTML = this.props.clip.name;
        }
        else if (key === "Enter") {
            this.setState({renaming: false});
            const el = document.getElementById(this.props.clip.id)
            const newName = el.textContent || el.innerText
            this.props.renameClip(newName)
        }
    }

    //TODO: Put these functions (repeated in clipsview) in an aux class
    getOwnerNameFromClip(clip){
        return clip.ownerFirstName + " " + clip.ownerLastName;
    }

    convertDurationToString(duration){
        if (duration > 10000000 || duration <= 0) {
            return "?"
        }
        const zeroPad = (num, places) => String(num).padStart(places, '0')
        const minutes = parseInt(duration / 60);
        const seconds = duration % 60;
        return minutes + ":" + zeroPad(seconds, 2)
    }

    renderDurationInDiv(duration) {
        return(
            <div className="horizontal-container align-right">
                <div data-test="field-clip-length" className="clip-list-text">
                    {this.convertDurationToString(duration)}
                </div>
            </div>
        )
    }

    formatTotalViews(views, classNames){
        let className = "clip-list-text";
        if (classNames){
            className = classNames;
        }

        return(
            <div data-test="field-clip-totalViews" className={className}>
                {views}
            </div>
        )
    }

    formatWatched(percentage, classNames = "clip-list-text"){
        percentage = Math.round(percentage*10)/10;
        return(
            <div data-test="field-clip-watched" className={classNames}>
                {percentage}%
            </div>
        )
    }

    convertLastUpdatedToString(lastUpdated) {
        const date = new Date(lastUpdated);
        const locale = "en-US" //TODO swap out depending on localization
        return date.toLocaleDateString(locale)
    }

    formatLastUpdated(lastUpdated, classNames){
        return (<div data-test="field-clip-date" className={classNames}>{`${this.convertLastUpdatedToString(lastUpdated)}`}</div>)
    }

    setClipHasThumbnail(clip, value) {
        clip.hasThumbnail = value
    }

    renderClipPreview(clip) {
        return (
                    <ClipThumbnail 
                        clip = {clip}
                        setClipHasThumbnail = {(clip, value) => this.setClipHasThumbnail(clip, value)}
                    />
        )
    }

    renderClipName(clip, provided) {
         return (
            <div data-test="field-clip-title" className="clip-list-text clip-list-name" data-test="clip-name" id={clip.id} ref={provided.innerRef} {...provided.draggableProps}  {...provided.dragHandleProps} 
                tabIndex="-1" contenteditable={this.state.renaming ? "true" : "false"} onKeyDown={e => this.onKeyPressed(e.key)} onBlur={() => this.onKeyPressed("Escape")}
                onClick={() => this.props.onClipClick()} >
                    {clip.name}
            </div>
         )
    }

    onHideMenu() {
        this.props.onHideMenu()
        this.setState({ menuExpanded: false })
    }

    onMenuClick() { 
        if (this.state.menuExpanded) {
            this.props.onHideMenu()
            this.setState({ menuExpanded: false })
        }
        else {
            this.props.onShowMenu()
            this.setState({ menuExpanded: true })
        }
    }

    renderClipMenu() {
        return (

                <div data-test="btn-clip-dots" className="clip-menu-button" tabIndex="-1" onBlur={() => this.onHideMenu()} onClick={() => this.onMenuClick()} >
                    <div className="clip-menu" style={{visibility: this.state.menuExpanded ? "visible" : "hidden"}}>
                        <div data-test="btn-clip-dots-rename" className="clip-menu-item" onClick={() => this.processRenameClipClick()}> Rename Clip </div>
                        <div data-test="btn-clip-dots-delete" className="clip-menu-item" onClick={() => this.props.deleteClip()}> Delete Clip </div>
                        <div data-test="btn-clip-dots-generateThumbnail" className="clip-menu-item" onClick={() => this.props.onGenerateNewImage()}> Generate Thumbnail </div>
                        <div data-test="btn-clip-dots-uploadThumbnail" className="clip-menu-item" onClick={() => this.props.onUploadThumbnail()}>
                            <label htmlFor="upload-thumbnail"> Upload Thumbnail </label>
                        </div>
                        <div data-test="btn-clip-dots-moveTo" className="clip-menu-item move-to-item"> Move To
                            <img className="move-to-icon" src={MoveToClipMenuIcon} alt={"move to icon"} />
                            <div className="move-to-menu">
                                <MoveClipMenu 
                                    projects={this.props.projects} 
                                    currentProjectId={this.props.currentProjectId}
                                    onMoveToProject={projectId => this.props.onMoveToProject(projectId)}
                                />
                            </div>
                         </div>
                    </div>
                </div>
        )
    }

    renderClipLinkButton(clip) {
        return (
            <div data-test="btn-clip-copyURL" className="clip-link-button" alt="copy clip link" onClick={() => {navigator.clipboard.writeText(clip.previewURL)}}/>
        )
    }

    renderUserImage() {
        return (
            <div data-test="clip-owner-logo" style={{ marginRight: "3px", width: "35px", height: "35px"}}>
                <UserLogo showFullNameOnHover onlyInitials={!this.props.clip.ownerInfo.hasCustomPicture} userInfo={this.props.clip.ownerInfo} orgID={this.props.orgId} userID={this.props.clip.ownerID} />
            </div>
        )
    }
                  

    render() {
        const draggingThisClip = this.props.draggingId === this.props.clip.id

        const provided = this.props.provided
        const clip = this.props.clip
        const cellMode = this.props.cellMode
        const dragging = this.props.dragging

        // Columns layout and width must match that of PortalClipListItem -> renderListHeader and ClipsView -> renderTableHeader
        const columnsWidth = cellMode ? Constants.columnsWidthCell : Constants.columnsWidthDesktop

        return(
            <div className={dragging ? "clip-list-row clip-list-row-dragging" : "clip-list-row clip-list-row-not-dragging"} data-test="card-clip" style={{ height: draggingThisClip ? "2px" : "48px" }}>
                
                {/* 1st Column */}
                <div className="clip-or-project-first-column" style={{width: columnsWidth[0] + "%"}} >
                     {!draggingThisClip && this.renderClipPreview(clip)}
                     {this.renderClipName(clip, provided)}
                    <div className="horizontal-container align-right clip-menu-container" >
                        {!dragging && this.renderClipMenu()}
                        {!dragging && this.renderClipLinkButton(clip)}
                    </div>
                </div>
                
                {/* 2nd Column */}
                <div className="horizontal-container" style={{ width: columnsWidth[1] + "%"}}>
                    {!draggingThisClip && this.renderDurationInDiv(clip.durationInSeconds, "clip-list-duration")}
                </div>

                {/* 3rd Column */}
                <div style={{ width: columnsWidth[2] + "%", alignItems: "right" }}>
                    {!draggingThisClip && this.formatWatched(clip.watched, "clip-list-text")}  
                </div>

                {/* 4th Column */}
                <div style={{ width: columnsWidth[3] + "%", alignItems: "right" }}>
                    <div className="clip-list-text" data-test="field-clip-uniqueViews" >
                        {!draggingThisClip && clip.uniqueViews}
                    </div>    
                </div>

                {/* 5th Column */}
                <div style={{ width: columnsWidth[4] + "%", alignItems: "right" }}>
                    {!draggingThisClip && this.formatTotalViews(clip.totalViews, "clip-list-text")}  
                </div>
                
                {/* 6th Column (optional) */}
                {!cellMode &&
                    <div style={{width: columnsWidth[5] + "%"}}>
                        {!draggingThisClip && this.formatLastUpdated(clip.lastUpdated, " clip-list-text clip-list-text-last-updated")}
                    </div>
                }

                {/* Last Column */}
                <div className="horizontal-container align-right" style={{ display: "flex", height: "100%", width: columnsWidth[columnsWidth.length - 1] + "%" }}>
                    {!draggingThisClip && this.renderUserImage()}
                </div>
            </div>
        )
    }
}

