import React from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './userview.css'

import * as Constants from '@/constants'

import FormInput from './forminput/forminput';
import CountryDropdownInput from './countrydropdowninput/countrydropdowninput';
import PortalListItem from '@/viewport/components/portallistitem/portallistitem';
import { checkNewPassword } from '@/components/passwordchecker/passwordchecker';

import { AuthService } from '@/services/authservice';

import logoutButtonImage from "@/images/viewport/userview/Logout_icon_userView.svg"
import logoutButtonImageHovered from "@/images/viewport/userview/Logout_icon_hovered.svg"
import logoutButtonImagePressed from "@/images/viewport/userview/Logout_icon_pressed.svg"

import inviteButtonImage from "@/images/viewport/userview/invite.svg"
import inviteButtonImageHovered from "@/images/viewport/userview/invite-hover.svg"
import inviteButtonImagePressed from "@/images/viewport/userview/invite-press.svg"

export default class UserView extends React.Component {

    constructor(props) {
        super(props);

        document.title = "Profile - HoloSuite Portal"

        this.state = {
            activeOrganization: this.props.activeOrganization,
            organizations: this.props.organizations,
            // copy of userInfo for state
            userInfo: JSON.parse(JSON.stringify(this.props.userInfo)),
            // used for inviting new users to an organization
            invitingToOrg: "",
            working: false,
        }

        for (let i = 0; i < this.state.organizations.length; i++) {
            this.state.organizations[i].expanded = this.state.organizations[i].id === this.state.activeOrganization.id
        }

        this.handleInviteChange = this.handleInviteChange.bind(this);
    }

    toggleExpanded(id){
        for (let i = 0; i < this.state.organizations.length; i++) {
            if (this.state.organizations[i].id === id) {
                let organizations = this.state.organizations
                organizations[i].expanded = !this.state.organizations[i].expanded
                this.setState({ organizations: organizations })
            }
        }
    }
    
    isOrganizationExpanded(id) {
        for (let i = 0; i < this.state.organizations.length; i++) {
            if (this.state.organizations[i].id === id) {
                return this.state.organizations[i].expanded
            }
        }
    }

    setActiveOrganization(org){
        // set active org globally
        this.props.setActiveOrganization(org)
        // refresh active org locally
        this.setState({activeOrganization : org});
    }

    updateInput(key, newValue, isAddress){
        const userInfo = this.state.userInfo;
        if (userInfo[key] !== undefined || userInfo.address[key] !== undefined) {
            if (userInfo[key] !== undefined) {
                userInfo[key] = newValue
            }
            else {
                userInfo.address[key] = newValue
            }
            this.setState({userInfo: userInfo})
        }
        else if (key === "New Password") {
            this.setState({newPassword: newValue})
        }
        else if (key === "Confirm New Password")  {
            this.setState({confirmNewPassword: newValue})
        }
        else {
            if (isAddress) {
                userInfo.address[key] = newValue
            }
            else {
                userInfo[key] = newValue
            }
        }
    }

    async onSaveChanges() {
        // just in case, should never happen since button should be disabled in that case
        if (!checkNewPassword(this.state.newPassword) || !this.passwordsMatch()) {
            return;
        }

        this.setState({working: true})
        let promisePass = "success"
        let promiseInfo = "success"


        if (this.passChanged()) {
            // try to update in 10duke, if that succeeds update password locally in app, on failure alert user
            promisePass = AuthService.changeUserPassword(this.state.userInfo, this.state.newPassword)
        }

        if (this.infoChanged()) {
            // try to update in 10duke, if that succeeds update locally in app, on failure alert user
            promiseInfo = AuthService.putUserInfo(this.state.userInfo, this.props.updateUserInfo)
        }

        await promisePass
        await promiseInfo
        this.setState({ working: false })
    }

    passwordsMatch() {
        if (this.state.newPassword === this.state.confirmNewPassword) {
            return true
        }
        // nothing in Confirm New Password and password won't change -> don't care that they don't really match
        if (!this.passChanged() && !this.state.confirmNewPassword) {
            return true
        }
        return false;
    }
    
    infoChanged() {
        for (let key in this.state.userInfo) {
            if (key === "address") {
                continue;
            }
            if (this.state.userInfo[key] !== this.props.userInfo[key]) {
                return true
            }
        }
        for (let key in this.state.userInfo.address) {
            if (this.state.userInfo.address[key] !== this.props.userInfo.address[key]) {
                return true
            }
        }
        return false
    }

    passChanged() {
        if (this.state.newPassword !== "" && this.state.newPassword !== undefined && this.state.newPassword !== AuthService.getPassword()) {
            return true
        }
        return false;
    }

    infoOrPassChanged() {
        return this.infoChanged() || this.passChanged()
    }

    renderSaveButton() {
        return (
            <div data-test="btn-save" className={checkNewPassword(this.state.newPassword) && this.passwordsMatch() && this.infoOrPassChanged() && !this.state.working ? "user-save-button" : "user-save-button-disabled"} onClick={() => this.onSaveChanges()}> 
                <div className="center align-center"> SAVE </div>
            </div>
        )
    }               

    renderColumn(totalColumns, columnContent, lastColumn) {
        return (
            <div className="vertical-container" style={{width: "" + 1 / totalColumns * 100 + "%"}}>
                <div className={totalColumns > 1 ? "user-2-or-3-columns" : "user-only-column"}>
                    {columnContent}
                    {lastColumn && <div className="profile-input-filler">{this.renderSaveButton()}</div>}
                </div>
            </div>
        )
    }

    getInputValue(key) {
        if (key === "New Password") {
            return this.state.newPassword
        }
        if (key === "Confirm New Password") {
            return this.state.confirmNewPassword
        }
        return this.state.userInfo[Constants.tenDukeKeys[key]]
    }
    
    renderProfileForm(orgIsActive){
        const column1Keys = ["First Name", "Last Name", "Display Name", "Professional Title", "Nickname"];
        const column2Keys = ["Street Address", "City", "Postal Code", "Region", "Country"];
        const column3Keys = ["Email", "New Password", "Confirm New Password"];


        let column1Content = column1Keys.map(key => (
            <FormInput
            label={key}
            id={key}
            inputUpdate={(value) => this.updateInput(Constants.tenDukeKeys[key], value)}
            inputValue={this.state.userInfo[Constants.tenDukeKeys[key]]}
            focusFirst={key === column1Keys[0] && orgIsActive}
            password={key.includes("Password")}
            disabled={this.state.working}
            />
        ));

        let column2Content = column2Keys.map(key => (
            key === "Country" ? 
                <CountryDropdownInput
                label={key}
                id={key}
                inputUpdate={(value) => this.updateInput(Constants.tenDukeKeys[key], value, true)}
                inputValue={this.state.userInfo.address ? this.state.userInfo.address[Constants.tenDukeKeys[key]] : ""}
                password={key.includes("Password")}
                disabled={this.state.working}
                />
            :
                <FormInput
                label={key}
                id={key}
                inputUpdate={(value) => this.updateInput(Constants.tenDukeKeys[key], value, true)}
                inputValue={this.state.userInfo.address ? this.state.userInfo.address[Constants.tenDukeKeys[key]] : ""}
                password={key.includes("Password")}
                disabled={this.state.working}
                />
        ));

        // with fillers to match height of column1Content+column3Content (for 2-columns view)
        let column2ContentWithFillers = column2Keys.concat(["", ""]).map(key => (
           key === "Country" ? 
                <CountryDropdownInput
                label={key}
                id={key+"F"}
                inputUpdate={(value) => this.updateInput(Constants.tenDukeKeys[key], value, true)}
                inputValue={this.state.userInfo.address ? this.state.userInfo.address[Constants.tenDukeKeys[key]] : ""}
                password={key.includes("Password")}
                disabled={this.state.working}
                />
            :
                <FormInput
                label={key}
                id={key+"F"}
                inputUpdate={(value) => this.updateInput(Constants.tenDukeKeys[key], value, true)}
                inputValue={this.state.userInfo.address ? this.state.userInfo.address[Constants.tenDukeKeys[key]] : ""}
                password={key.includes("Password")}
                disabled={this.state.working}
                />
        ));

        let column3Content = column3Keys.map(key => (
            <FormInput disabled={key === "Email" || this.state.working}
            label={key}
            id={key}
            inputUpdate={(value) => this.updateInput(Constants.tenDukeKeys[key], value)}
            inputValue={this.getInputValue(key)}
            passwordsMatch={this.passwordsMatch()}
            password={key.includes("Password")}
            />
        ));

        // with fillers to match height of other 2 columns (for 3-columns view)
        let column3ContentWithFillers = column3Keys.concat([""]).map(key => (
            <FormInput disabled={key === "Email" || this.state.working}
            label={key}
            id={key+"F"}
            inputUpdate={(value) => this.updateInput(Constants.tenDukeKeys[key], value)}
            inputValue={this.getInputValue(key)}
            passwordsMatch={this.passwordsMatch()}
            password={key.includes("Password")}
            />
        ));

        let totalColumns =  this.props.width > Constants.WIDTH_FOR_DESKTOP_VIEW ? 3 :
                            this.props.width > Constants.WIDTH_FOR_SHOWING_SIDEBAR ? 2 : 1

        if (totalColumns === 3)
        {
            return(
                <div className="horizontal-container hidden-scroll full-width" style={{justifyContent:"flex-start", paddingLeft:"25px", alignItems:"top"}}>
                    {this.renderColumn(totalColumns, column1Content)}
                    {this.renderColumn(totalColumns, column2Content)}
                    {this.renderColumn(totalColumns, column3ContentWithFillers, "lastColumn")}
                </div>
            );
        }
        if (totalColumns === 2)
        {
            return(
                <div className="horizontal-container hidden-scroll full-width" style={{justifyContent:"flex-start", paddingLeft:"25px"}}>
                    {this.renderColumn(totalColumns, column1Content.concat(column3Content))}
                    {this.renderColumn(totalColumns, column2ContentWithFillers, "lastColumn")}
                </div>
            );
        }
        return(
            <div className="horizontal-container hidden-scroll full-width" style={{justifyContent:"flex-start", paddingLeft:"25px"}}>
                {this.renderColumn(totalColumns, column1Content.concat(column3Content).concat(column2Content), "lastColumn")}
            </div>
        );
    }

    renderOrganizationList(){
        let orgList = [];

        this.props.organizations.forEach(organization => {
            orgList.push(
                <PortalListItem 
                organization={organization}
                activeListItem={this.props.activeOrganization.id === organization.id}
                itemContent={this.renderProfileForm(this.props.activeOrganization.id === organization.id)}
                setActiveOrganization={org => this.setActiveOrganization(org)}
                setOrgHasCustomPicture={(org, value) => this.props.setOrgHasCustomPicture(org, value)}                
                expanded={this.isOrganizationExpanded(organization.id)}
                toggleExpanded={org => this.toggleExpanded(org.id)}
                userID={this.state.userInfo.id}
                userInfo={this.state.userInfo}
                refreshSidebarPicture = {() => this.props.refreshSidebarPicture()}
                arcturusUser = {this.props.arcturusUser}
                width={this.props.width}
                height={this.props.height}
                />
            );
        })
        return orgList;
    }

    logout(clickEvent){
        clickEvent.stopPropagation();
        AuthService.logout()
    }

    handleInviteChange(event) {
        const invitingFirstName = document.getElementById("invitingFirstName").value;
        const invitingLastName = document.getElementById("invitingLastName").value;
        const invitingEmail = document.getElementById("invitingEmail").value;

        if (invitingFirstName && invitingLastName && invitingEmail) {
            // Hack (MS): change button class with vanilla JS instead of react state so it's applied inside alert box
            document.getElementById("invite-button").className = "portal-button";
            this.setState({inviteFormReady: true})
        }
        else {
            document.getElementById("invite-button").className = "portal-button-disabled";
            this.setState({inviteFormReady: false})
        }
    }

    renderInviteNewMemberInput(label,id) {
        return (
            <div className="profile-input">
                <label>
                    {label}
                </label>
                <input id={id} onChange={this.handleInviteChange}/>
            </div>
        )
    }         

    renderInviteNewMemberInputs() {
        return (
            <div>
                {this.renderInviteNewMemberInput("Last Name", "invitingLastName")}
                {this.renderInviteNewMemberInput("First Name", "invitingFirstName")}
                {this.renderInviteNewMemberInput("Email", "invitingEmail")}
            </div>
        )
    }
        

    async inviteNewMember(organization) {
        this.setState({ 
            invitingToOrg: organization
        }, async function() {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="invite-member-popup">
                            <div className="vertical-container align-center">
                                <div className="invite-title">
                                    Invite a new member to {this.state.invitingToOrg.name}
                                </div>
                                    
                                <div>
                                    {this.renderInviteNewMemberInputs()}
                                </div>
                    
                                <div className="horizontal-container">
                                    <div className="portal-button" onClick={onClose}>CANCEL</div>
                                    <div style={{width:50}}/>
                                    <div id="invite-button" className="portal-button-disabled" onClick={async () => {if (this.state.inviteFormReady) {await this.sendInvite(); onClose();}}}>INVITE</div>
                                </div>
                            </div>
                        </div>
                    );
                }
            })
        })
    }

    async sendInvite() {
        const invitingFirstName = document.getElementById("invitingFirstName").value;
        const invitingLastName = document.getElementById("invitingLastName").value;
        const invitingEmail = document.getElementById("invitingEmail").value;

        const result = await AuthService.sendNewUserInvite(this.state.userInfo, 
                                        invitingEmail, 
                                        invitingFirstName,
                                        invitingLastName,
                                        this.props.userInfo.firstName + " " + this.props.userInfo.lastName, 
                                        this.state.invitingToOrg.id, 
                                        this.state.invitingToOrg.name)
        if (result.data && result.data.accepted) {
            alert("Invite Sent!")
        }
        else if (result.error) {
            alert(result.error)
        }
        else {
            alert("Error creating invite")
        }
    }

    render() {
        const viewportClassNames = `ViewportView UserView`;

        return (
            <div className={viewportClassNames} >
                <div className="horizontal-container full-width">
            
                    <div className="horizontal-container user-logout-container" >
                        <div data-test="btn-logout" className="horizontal-container align-left-not-justify user-button" onClick={this.logout}  data-title="Logout" style={{height:"54px", width:"54px"}} >
                            <img src={logoutButtonImage} alt="Logout"></img>
                            <img src={logoutButtonImageHovered} className="user-button-hovered" alt="Logout"></img>
                            <img src={logoutButtonImagePressed} className="user-button-pressed" alt="Logout"></img>
                        </div>
                    </div>

                    <div className="horizontal-container align-left-not-justify user-invite-container">
                        { this.props.arcturusUser &&
                            <div data-test="btn-invite-new-user" className="horizontal-container align-left-not-justify user-button"  onClick={() => this.inviteNewMember(this.state.activeOrganization)} data-title="Invite New Member" style={{height:"54px", width:"54px"}}>
                                <img src={inviteButtonImage} alt="Invite"></img>
                                <img src={inviteButtonImageHovered} className="user-button-hovered" alt="Invite"></img>
                                <img src={inviteButtonImagePressed} className="user-button-pressed" alt="Invite"></img>
                            </div>
                        }
                    </div>
                </div>
                {this.renderOrganizationList()}
            </div>
        );
    }
}