import axios from "axios"
import * as Constants from '@/constants'

const apiURL = process.env.REACT_APP_PORTAL_API_URL
const picturesURL = process.env.REACT_APP_PORTAL_AWS_S3_URL

export let PictureService = (() => {

    return {
        changeUserPicture: async (pic, userID, orgID) => {
            try {
                
                // Read image from disk as a Buffer
                const image = new File([pic], userID+".png", {type: pic.type});
                
                // Create a form and append image with additional fields
                const form = new FormData();
                form.append('file', image, image.name);
                form.append('userID', userID)
                form.append('orgID', orgID)
                form.append('arcturus_org_id', Constants.arcturusOrgId)

                let response = await axios.post(apiURL+'/changeUserPicture', form, 
                                    {userID: userID, orgID: orgID}
                                );
                return response.data;
            }
            catch(err) {
                console.log("Error changing user picture", err)
                return
            }
        },

        changeOrgPicture: async (pic, userID, orgID) => {
            try {
                
                // Read image from disk as a Buffer
                const image = new File([pic], orgID+".png", {type: pic.type});
                
                // Create a form and append image with additional fields
                const form = new FormData();
                form.append('file', image, image.name);
                form.append('userID', userID)
                form.append('orgID', orgID)
                form.append('arcturus_org_id', Constants.arcturusOrgId)

                let response = await axios.post(apiURL+'/changeOrgPicture', form, 
                                    {orgID: orgID}
                                );
                return response.data;
            }
            catch(err) {
                console.log("Error changing org picture", err)
                return
            }
        },

        changeClipPicture: async (pic, userID, orgID, clipURI) => {
            try {
                
                // Read image from disk as a Buffer
                const image = new File([pic], clipURI+".png", {type: pic.type});
                
                // Create a form and append image with additional fields
                const form = new FormData();
                form.append('file', image, image.name);
                form.append('userID', userID)
                form.append('orgID', orgID)
                form.append('arcturus_org_id', Constants.arcturusOrgId)

                let response = await axios.post(apiURL+'/changeClipPicture', form, 
                                    {orgID: orgID}
                                );
                return response.data;
            }
            catch(err) {
                console.log("Error changing clip picture", err)
                return
            }
        },

        //TODO handle object expiry without using `new Date();` we could support caching of the images unless they've actually been changed
        getUserPictureURL(userID, orgID) {
            return picturesURL + "/userProfilePictures/"+orgID+"/"+userID+".png?" + new Date();
        },

        //TODO handle object expiry without using `new Date();` we could support caching of the images unless they've actually been changed
        getOrgPictureURL(orgID) {
            return picturesURL + "/orgPictures/"+orgID+".png?" + new Date();
        },

        getAllOrgsWithPictures: async () => {
            try {
                
                let response = await axios.post(apiURL+'/getAllOrgsWithPictures');
                return response.data;
            }
            catch(err) {
                console.log("Error getting all org available pictures", err)
                return
            }
        },

        getClipThumbnailURL(clipURI) {
            return picturesURL + "/clipPictures/"+clipURI+".png"
        }


        
    };
  })();