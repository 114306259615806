import { FunctionComponent } from 'react';
import { useScreenDimension } from './useScreenDimension';

type ScreenDimensionChildren = (screenWidth: number, screenHeight: number) => any;

interface IScreenDimensionProps {
  children: ScreenDimensionChildren;
}

export const ScreenDimension: FunctionComponent<IScreenDimensionProps> = ({
  children,
}) => {
  const screenWidth: number = useScreenDimension().width;
  const screenHeight: number = useScreenDimension().height;

  return children(screenWidth, screenHeight);
};