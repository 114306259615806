import React from 'react';

import { PictureService } from '@/services/pictureservice';


export default class UserLogo extends React.Component {

    getInitialsFromUserInfo(info) {
        if (!info || !info.firstName || !info.lastName) {
            return ""
        }
        return info.firstName.charAt(0).concat(info.lastName.charAt(0))
    }
    
    getFullNameFromUserInfo(info) {
        if (!info || !info.firstName || !info.lastName || info.firstName === "?") {
            return "Unknown User"
        }
        return info.firstName + " " + info.lastName
    }
    

    render() {
        if (this.props.userInfo && this.props.userInfo.profilePic) {
            return (
                <div className={this.props.invertColors ? "invert-border user-display-picture" : "user-display-picture"}>
                     <img src={this.props.userInfo.profilePic} alt="user logo" />
                </div>
            )
        }

        if (this.props.showFullNameOnHover) {
            if (this.props.onlyInitials) {
                return (
                    <div className="user-picture-container" data-title={this.getFullNameFromUserInfo(this.props.userInfo)}>
                        <div className={this.props.invertColors ? "invert-border user-display-picture" : "user-display-picture"}>
                            <div className="user-initials-container">
                                <div className={this.props.invertColors ? "invert-text user-initials align-center" : "user-initials align-center"}> 
                                    {this.getInitialsFromUserInfo(this.props.userInfo)} 
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            return(     
                <div className="user-picture-container" data-title={this.getFullNameFromUserInfo(this.props.userInfo)}>
                    <div className={this.props.invertColors ? "invert-border user-display-picture" : "user-display-picture"}>
                        <img id="currentPhoto" className="user-photo" src={PictureService.getUserPictureURL(this.props.userID, this.props.orgID) + String(this.props.imgUpdater)} 
                            onError={({ currentTarget }) => {currentTarget.className = "hidden-user-photo"}}
                            onLoad={({ currentTarget }) => {currentTarget.className = "user-photo"}}
                            alt={"custom user profile"} 
                        /> 
                        <div className="user-initials-container">
                            <div className={this.props.invertColors ? "invert-text user-initials align-center" : "user-initials align-center"}> 
                                {this.getInitialsFromUserInfo(this.props.userInfo)} 
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.onlyInitials) {
            return (
                <div className={this.props.invertColors ? "invert-border user-display-picture" : "user-display-picture"}>
                    <div className="user-initials-container">
                        <div className={this.props.invertColors ? "invert-text user-initials align-center" : "user-initials align-center"}> 
                            {this.getInitialsFromUserInfo(this.props.userInfo)} 
                        </div>
                    </div>
                </div>
            )
        }

        return(     
            <div className={this.props.invertColors ? "invert-border user-display-picture" : "user-display-picture"}>
                <img id="currentPhoto" className="user-photo" src={PictureService.getUserPictureURL(this.props.userID, this.props.orgID) + String(this.props.imgUpdater)} 
                    onError={({ currentTarget }) => {currentTarget.className = "hidden-user-photo"}}
                    onLoad={({ currentTarget }) => {currentTarget.className = "user-photo"}}
                    alt={"custom user profile"} 
                /> 
                <div className="user-initials-container">
                    <div className={this.props.invertColors ? "invert-text user-initials align-center" : "user-initials align-center"}> 
                        {this.getInitialsFromUserInfo(this.props.userInfo)} 
                    </div>
                </div>
            </div>
        )
    }
}