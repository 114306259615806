import axios from "axios"
const apiURL = process.env.REACT_APP_PORTAL_API_URL

export let ComputeService = (() => {

    //public methods
    return {
        getComputeUsage: async (orgName) => {
            try {
                let response = await axios.post(apiURL+'/computeUsage', {orgName: orgName});
                return response.data;
            }
            catch (error) {
                console.log("Error retrieving compute usage", error)
                return
            }
        },

        getEntitlement: async (orgId) => {
            try {
                let response = await axios.post(apiURL+'/computeEntitlement', {orgId: orgId});
                return response.data;
            }
            catch (error) {
                console.log("Error retrieving compute entitlement", error)
                return
            }
        }
    };
  })();