/* Not Currently Used */
import React from 'react'

import './homeview.css';

// Note(MS): made this import as part as code reorganizing since renderHeader() uses this styles, to be refactored if we'll use this
import '../../welcome/welcome.css';


export default class HomeView extends React.Component {
    
    renderHeader(){
        return(
            <div className="vertical-container" style={{alignItems: "flex-start"}}>
                <div className="horizontal-container">
                    <div className="welcome-header-title-text">
                    Welcome to the 
                    </div>
                    <div className="welcome-header-title-text-bold">
                    HOLOSUITE PORTAL
                    </div>
                </div>

                <div className="welcome-header-text">
                A central place to manage your HoloSuite account, licenses, plus see and play all your shared and published clips while collecting view analytics.
                </div>
            </div>
        );
    }

    renderContentContainer(content, title, altBackground, fitContent){
        let className = "home-content-container";
        if (fitContent){
            className += " home-content-container-fit";
        }
        else if (altBackground){
            className += " home-content-container-alt-bg";
        }
        return(
            <div className={className}>
                <div className="home-content-container-header">
                    <div className="home-label-icon"/>
                    <div className="home-label-title">
                    {title}
                    </div>
                </div>
                <div className="home-content-area">
                    {content}
                </div>
            </div>
        );
    }

    renderContentCard(title, key, index){
        return(
            <div className="home-content-card" key={key + index}>
                <div className="home-content-card-label">
                    <div className="home-content-card-label-text">
                    {title}
                    </div>
                </div>
            </div>
        );
    }


    renderContentListItem(title, description, index, displayTwoIcons){
        let rightIcon = [];
        let leftIcon = []
        if (displayTwoIcons){
            leftIcon.push(<div className="home-content-list-icon-left"/>);
            rightIcon.push(<div className="home-content-list-icon-right"/>);
        }
        else{
            leftIcon.push(<div className="home-content-list-icon-left-round"/>);
        }
        return(
            <div className="home-content-list-item" key={title + index}>
                <div className="horizontal-container" style={{alignItems:"center"}}>
                    {leftIcon}
                    <div className="home-content-list-title">
                    {title}
                    </div>
                </div>
                <div className="horizontal-container" style={{alignItems:"center"}}>
                    <div className="home-content-list-text">
                    {description}
                    </div>
                    {rightIcon}
                </div>
            </div>
        )
    }

    renderContentList(listCount, title, description, displayTwoIcons){
        let content = [];

        for (let i = 0; i < listCount; i++){
            content.push(
                this.renderContentListItem(title, description, i, displayTwoIcons)
            );
        }

        return (
            <div className="home-content-list-container">
            {content}
            </div>
        );
    }

    renderLearning(){
        let content = [];
        for (let i = 0; i < 29; i++){
            content.push(this.renderContentCard("LinkName", "Learning", i))
            if (i < 28){
                content.push(<div style={{width:"15px", minWidth:"15px"}}/>)
            }
        }
        return(
            this.renderContentContainer(content, "LEARNING")
        );
    }

    renderSupport(){
        let content = [];
        for (let i = 0; i < 19; i++){
            content.push(this.renderContentCard("LinkName", "Support", i))
            if (i < 18){
                content.push(<div style={{width:"15px", minWidth:"15px"}}/>)
            }
        }
        return(
            this.renderContentContainer(content, "SUPPORT")
        );
    }

    renderUpdate(){
        let content = this.renderContentCard("Download", "Update", 0)
        return(
            this.renderContentContainer(content, "HOLOSUITE UPDATE", true, true)
        );
    }

    renderVersionHistory(){
        let content = this.renderContentList(10, "VersionName", "Release Notes", true)
        return(
            this.renderContentContainer(content, "HOLOSUITE VERSION HISTORY", true)
        );
    }

    renderNewsFeed(){
        let content = this.renderContentList(10, "ContentName", "Short description of article goes here")
        return(
            this.renderContentContainer(content, "NEWS FEED", true)
        );
    }

    render() {
        const viewportClassNames = `ViewportView home-view`;
        let horizontalSpacer = (
            <div style={{width:"8px", minWidth:"8px"}}/>
        )
        return (
            <div className={viewportClassNames}>
                {this.renderHeader()}
                {this.renderLearning()}
                {this.renderSupport()}

                <div className="horizontal-container">
                    {this.renderUpdate()}
                    {horizontalSpacer}
                    {this.renderVersionHistory()}
                    {horizontalSpacer}
                    {this.renderNewsFeed()}
                </div>    
            </div>
        );
    }
}