import axios from "axios"
import {Project, Clip} from "../models/datatypes"

import * as Constants from '@/constants' 

import { AuthService } from "./authservice"

const streamApiURLs = process.env.REACT_APP_STREAM_API_URL.split(",")
const portalApiURL = process.env.REACT_APP_PORTAL_API_URL

export let DataService = {

    getAllProjectsForOrganization: async (orgId) => {
        let projects = []
        await axios.all(streamApiURLs.map((apiURL) => axios.get(`${apiURL}/usage/allprojects/${orgId}`))).then((responses) => responses.forEach(
            (response, index) => {
                for (let projectData of response.data.projects) {
                    projects.push(new Project(projectData, streamApiURLs[index]))
                }
            })
        )
        return projects
    },

    getAllClipsForOrg: async (orgId) => {
        let clips = []
        let orgViews = []
        let id = 1

        await axios.all(streamApiURLs.map((apiURL) => axios.get(`${apiURL}/usage/allclipsinfo/${orgId}`))).then((responses) => responses.forEach(
            (response, index) => {
                if (response.data.error) {
                    alert(response.data.error)
                }
                for (let clipInfo of response.data.clipInfo) {
                    clipInfo.id = id
                    clips.push(new Clip(clipInfo, streamApiURLs[index]))
                    id++
                }
                let orgViewsToAdd = response.data.orgViews
                // remove trailing zeros and convert from viewChunks to minutes
                while(orgViewsToAdd.length > 6 && orgViewsToAdd[orgViewsToAdd.length-1] === 0) { 
                    orgViewsToAdd.pop();                  
                }
                for (let i = 0; i < orgViewsToAdd.length; i++) {
                    orgViewsToAdd[i] /= 30
                }
                // add orgViewsToAdd from streamAPI service to total orgViews
                for (let i = 0; i < orgViewsToAdd.length; i++) {
                    orgViews[i] = orgViews[i] ? orgViews[i] + orgViewsToAdd[i] : orgViewsToAdd[i]
                }
            })
        )
        return {clips: clips, orgViews: orgViews}
    },

    getSingleClipInfo: async (apiURL, clipId) => {
        let clipInfo = {}
        await axios.get(`${apiURL}/usage/singleclipinfo/${clipId}`).then((response) => {
            clipInfo = response.data
        })
        return clipInfo
    },

    createNewProject: async (projectName, orgId, accessToken, openIdToken) => {
        await AuthService.refreshTokenIfAboutToExpire()
        try {
            const res = await axios.post(portalApiURL + '/createNewProject', 
                            { projectName: projectName, 
                                orgId: orgId, 
                                accessToken: accessToken, 
                                openIdToken: openIdToken,
                                streamApiURL: streamApiURLs[0],
                            });
            return { newProjectId: res.data, newProjectStreamApiURL: streamApiURLs[0] }
        }
        catch {
            console.log("Error creating new project")
            return "-1"
        }
    },

    renameProject: async (projectId, streamApiURL, newName, orgId, accessToken, openIdToken) => {
        await AuthService.refreshTokenIfAboutToExpire()
        try {
            const res = await axios.post(portalApiURL + '/renameProject', 
                                { projectId: projectId, 
                                    newName: newName, 
                                    orgId: orgId, 
                                    accessToken: accessToken, 
                                    openIdToken: openIdToken,
                                    streamApiURL: streamApiURL,
                                });
            return res.data
        }
        catch {
            console.log("Error renaming project")
            return "error"
        }
    },

    deleteProject: async (projectId, streamApiURL, orgId, accessToken, openIdToken) => {
        await AuthService.refreshTokenIfAboutToExpire()
        try {
            const res = await axios.post(portalApiURL + '/deleteProject', 
                                { projectId: projectId, 
                                    orgId: orgId, 
                                    accessToken: accessToken, 
                                    openIdToken: openIdToken,
                                    streamApiURL: streamApiURL,
                                });
            return res.data
        }
        catch {
            console.log("Error deleting project")
            return "error"
        }
    },

    moveClipToProject: async (clipURI, streamApiURL, projectId, orgId, accessToken, openIdToken) => {
        await AuthService.refreshTokenIfAboutToExpire()
        try {
            const res = await axios.post(portalApiURL + '/moveClipToProject', 
                            { clipURI: clipURI, 
                                projectId: projectId, 
                                orgId: orgId, 
                                accessToken: accessToken, 
                                openIdToken: openIdToken,
                                streamApiURL: streamApiURL,
                            });
            return res.data
        }
        catch {
            console.log("Error moving clip to project")
            return "error"
        }
    },

    renameClip: async (clipURI, streamApiURL, newName, orgId, accessToken, openIdToken) => {
        await AuthService.refreshTokenIfAboutToExpire()
        try {
            const res = await axios.post(portalApiURL + '/renameClip', 
                            { clipURI: clipURI, 
                                newName: newName, 
                                orgId: orgId, 
                                accessToken: accessToken, 
                                openIdToken: openIdToken,
                                streamApiURL: streamApiURL,
                            });
            return res.data
        }
        catch {
            console.log("Error renaming clip")
            return "error"
        }
    },

    setClipThumbnailURL: async (clipURI, streamApiURL, clipThumbnailURL, orgId, accessToken, openIdToken) => {
        await AuthService.refreshTokenIfAboutToExpire()
        try {
            const res = await axios.post(portalApiURL + '/setClipThumbnailURL', 
                            { clipURI: clipURI, 
                                clipThumbnailURL: clipThumbnailURL,
                                orgId: orgId, 
                                accessToken: accessToken, 
                                openIdToken: openIdToken,
                                streamApiURL: streamApiURL,
                            });
            return res.data
        }
        catch {
            console.log("Error @ clipHasThumbnail")
            return "error"
        }
    },

    deleteClip: async (clipURI, streamApiURL, orgId, accessToken, openIdToken) => {
        await AuthService.refreshTokenIfAboutToExpire()
        try {
            const res = await axios.post(portalApiURL + '/deleteClip', 
                            { clipURI: clipURI, 
                                orgId: orgId, 
                                accessToken: accessToken, 
                                openIdToken: openIdToken,
                                streamApiURL: streamApiURL,
                            });
            return res.data
        }
        catch {
            console.log("Error deleting clip")
            return
        }
    },

}