import React from 'react'
import * as Constants from '@/constants'
import { Oval } from 'react-loader-spinner'

import './singleclipview.css'
import '../clips.css'

import {FlexibleXYPlot, 
        XAxis, 
        YAxis, 
        HorizontalGridLines, 
        VerticalGridLines, 
        LineMarkSeries} from 'react-vis';

import singleClipHeaderEye from "@/images/viewport/singleclipview/singleClipHeaderEye.svg";
import singleClipHeaderUniqueViewsOrange from "@/images/viewport/singleclipview/singleClipHeaderUniqueViewsOrange.svg";
import singleClipHeaderTotalViewsTeal from "@/images/viewport/singleclipview/singleClipHeaderTotalViewsTeal.svg";
import singleClipHeaderAvgWatchedBlue from "@/images/viewport/singleclipview/singleClipHeaderAvgWatchedBlue.svg";

import shared from "@/images/viewport/singleclipview/shared.svg";
import UUID from "@/images/viewport/singleclipview/UUID.svg";
import Clip_link_icon from "@/images/viewport/singleclipview/Clip_link_icon.svg";

import date from "@/images/viewport/singleclipview/date.svg";
import FPS from "@/images/viewport/singleclipview/FPS.svg";
import minutes from "@/images/viewport/singleclipview/minutes.svg";
import SIZE from "@/images/viewport/singleclipview/SIZE.svg";

import length from "@/images/viewport/singleclipview/length.svg";
import watched from "@/images/viewport/singleclipview/watched.svg";
import unique from "@/images/viewport/singleclipview/unique.svg";
import total from "@/images/viewport/singleclipview/total.svg";

import UserLogo from "@/components/userlogo/userlogo"

import ClipThumbnail from "@/viewport/components/clipthumbnail/clipthumbnail"

import { DataService } from '@/services/dataservice';

export default class SingleClipView extends React.Component {

    constructor(props) {
        super(props);

        document.title = "Clip - HoloSuite Portal"

        this.months=['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

        this.state = {
            loading: true
        }


        if(this.state.loading){ 
            DataService.getSingleClipInfo(this.props.clip.streamApiURL, this.props.clip.uri).then(async response => {

                let numDataPoints = response.lastMonthsViews.length
                let labels = []
                let date = new Date()
                for (let i = 0; i < numDataPoints; i++) {
                    let year = date.getFullYear() % 100
                    let month = this.months[date.getMonth()]
                    date.setMonth( date.getMonth() - 1) 
                    labels.push( month + " " + year )
                }

                this.setState({
                    loading: false,
                    startMonth: 5,
                    endMonth: 0,
                    labels: labels,
                    singleClipInfo: response
                })
            })
        }

        this.onStartChange = this.onStartChange.bind(this);
        this.onEndChange = this.onEndChange.bind(this);
    }
    
    shouldShow(i, numDataPoints) {
        if (numDataPoints <= 8) {
            return true
        }
        if (numDataPoints <= 16) {
            return i % 2 === 0
        }
        if (numDataPoints <= 24) {
            return i % 4 === 0
        }
        return i % 6 === 0
    }

    renderLineView(color, strokeStyle, strokeWidth, fill, size, data, showGrids, showAxis, key, top, left, symbol = "", orientation = "left"){
        let gridElements = [];
        let axisElements = [];
        
        if (showGrids){
            gridElements = [
                <HorizontalGridLines tickTotal={3} style={{stroke:"#B7B4C3"}} key={key + "horizontalGrid"}/>,
                <VerticalGridLines tickTotal={6} style={{stroke:"#B7B4C3"}} key={key + "verticalGrid"}/>
            ]
        }

        const maxValue = Math.round(Math.max(...data.map(o => o.y)));
        const yAxisValues = [
            0, 
            maxValue / 2.0, 
            maxValue
        ]
        const xAxisValues = []
        let dataPoints = this.state.startMonth - this.state.endMonth + 1
        for (let i = 0; i < dataPoints; i++) {
            xAxisValues.push(i);
        }
        
        if (showAxis){
        // hack (MS): to use symbol in tickFormat we split in two cases because tickFormat={i => i} causes display problems for YAxis
            if (symbol !== "") {
                axisElements = [
                    <XAxis 
                        key={key + "xAxis"} 
                        tickValues={xAxisValues}
                        tickFormat={i => this.shouldShow(i, dataPoints) && this.state.labels[this.state.startMonth - i]}
                        hideLine
                        tickSize={0}
                        top={264}
                        style={{
                          ticks: {fill: "#B7B4C3"},
                        }}
                    />,
                    <YAxis 
                        tickSize={0}
                        tickValues={yAxisValues} 
                        tickFormat={i => i + symbol}
                        key={key + "yAxis"}
                        orientation='right'
                        style={{
                          ticks: {fill: color},
                        }}
                        hideLine
                    />
                ];
            }
            else {
                axisElements = [
                    <YAxis 
                        left={left}
                        tickSize={0}
                        tickValues={yAxisValues} 
                        key={key + "yAxis"}
                        top={top}
                        orientation={orientation}
                        style={{
                          ticks: {fill: color},
                        }}
                        hideLine
                    />
                ];
            }
        }
        else{
            axisElements = [
                <YAxis 
                    tickSize={0}
                    tickValues={yAxisValues} 
                    key={key + "yAxis"}
                    top={top}
                    hideLine
                    hideTicks
                />,
            ];
        }
        return(
            <div className="overlay-graph" key={key}>
                <FlexibleXYPlot yDomain={symbol === "%" ? [0, 100] : [0, Math.max(maxValue,1)]}> 
                    {gridElements}
                    <LineMarkSeries
                        data={data}
                        color={color}
                        fill={fill}
                        size={size}
                        strokeStyle={strokeStyle}
                        strokeWidth={strokeWidth}
                    />
                    {axisElements}
                </FlexibleXYPlot>
            </div>
        );
    }

    getUniqueViewsValues(){
        let values = []
        const start = this.state.startMonth
        const end =  this.state.endMonth
        for (let i = start; i >= end; i--){
            values.push({x: start - i, y: this.state.singleClipInfo.lastMonthsViews[i].uniqueViews})
        }
        return values;
    }

    getTotalViewsValues(){
        const durationInSeconds = this.props.clip.durationInSeconds || 0
        // estimate each play in totalPlayCount = 2s
        const chunksInClip = Math.ceil (durationInSeconds / 2)
        let values = [];
        const start = this.state.startMonth
        const end =  this.state.endMonth
        for (let i = start; i >= end; i--){
            const totalPlayCount = Number(this.state.singleClipInfo.lastMonthsViews[i].totalViews) || "?"
            const totalViews = totalPlayCount !== "?" && durationInSeconds !== 0 ?
                Math.floor(totalPlayCount / chunksInClip) : "0"
            values.push({x: start - i, y: totalViews})
        }
        return values;
    }

    getWatchedViewValues(){
        const durationInSeconds = this.props.clip.durationInSeconds || 0
        
        let values = [];
        const start = this.state.startMonth
        const end =  this.state.endMonth
        for (let i = start; i >= end; i--){
            const uniqueViews = this.state.singleClipInfo.lastMonthsViews[i].uniqueViews
            const cappedPlayCount = this.state.singleClipInfo.lastMonthsViews[i].cappedViews
            // same logic as in `this.watched` in clip constructor in `datatypes.js`
            const watched = durationInSeconds > 0 && uniqueViews > 0 && cappedPlayCount > 0 ? cappedPlayCount * 100 / ( uniqueViews * Math.ceil (durationInSeconds / 2) ) : 0
            values.push({x: start - i, y: watched})
        }
        return values;
    }

    uniqueViewsLineView(){
        return(
            this.renderLineView("#FF6B00", "solid", "4", "#B7B4C3", "3", this.getUniqueViewsValues(), false, true, "graph4", 6, 0)
        );
    }

    totalViewsLineView(){
        return(
            this.renderLineView("#00C7E2", "dashed", "2", "#B7B4C3", "3", this.getTotalViewsValues(), false, true, "graph5", 16, 0)
        );
    }

    watchedViewLineView(){
        return(
            this.renderLineView("#1F93FF", "dashed", "2", "#B7B4C3", "0", this.getWatchedViewValues(), true, true, "graph6", 0, 10, "%", "right")
        );
    }

    renderClipViewsGraph(){
        return(
            <div data-test="graph-clip-stats" className="graph-container">
                {this.uniqueViewsLineView()}
                {this.totalViewsLineView()}
                {this.watchedViewLineView()}
            </div>
        );
    }

    renderClipViewsContent(){
        return(
            <div className="horizontal-container hidden-scroll full-width" style={{ justifyContent: "flex-start", paddingLeft: "50px", alignItems: "top" }}>
                {this.renderClipViewsGraph()}
            </div>
        )
    }

    renderMonthOption(value, label, disable, selected) {
        if(disable) {
            return (
                <option data-test="month-disabled" value={value} disabled> {label} </option>
            )
        }
        if (selected) {
            return (
                <option data-test="month-selected" value={value} className="option-enabled" selected> {label} </option>
            )
        }

        return (
            <option data-test="month-enabled" className="option-enabled" value={value}> {label} </option>
        )
    }

    onStartChange(event){
         this.setState({startMonth: Number( event.target.value )});
    }

    onEndChange(event){
         this.setState({endMonth: Number( event.target.value )});
    }

    renderMonthDropdowns() {

        let beginOptions = []
        let endOptions = []
        const labels = this.state.labels
        const currentBegin = this.state.startMonth
        const currentEnd = this.state.endMonth
        for (let i = 0; i < labels.length; i++) {
            beginOptions.push(this.renderMonthOption(i,labels[i], i <= currentEnd, i === currentBegin ))
            endOptions.push(this.renderMonthOption(i,labels[i], i >= currentBegin, i === currentEnd ))
        }

         return (
            <div className="horizontal-container dropdown-container">
                <select data-test="drop-month-start" onChange={this.onStartChange}>
                    {beginOptions}
                </select>
                <div style={{width:"2px"}} />
                -
                <div style={{width:"5px"}} />
                <select data-test="drop-month-end" onChange={this.onEndChange}>
                    {endOptions}
                </select>
            </div>
        )
    }

    renderClipViewsHeader() {
        return (
            <div className="portal-list-item-header">
                <div className="horizontal-container clip-list-text">
                    <div style={{width:10}}/>
                    <img src={singleClipHeaderEye} alt="clip views"/>
                    <div style={{width:10}}/>
                    CLIP VIEWS
                </div>
                <div className="horizontal-container">
                    <div data-title="Percentage Watched">
                        <img src={singleClipHeaderAvgWatchedBlue} alt="clip percentage watched"/>
                    </div>
                    <div data-title="Unique Views">
                        <img src={singleClipHeaderUniqueViewsOrange} alt="clip unique views"/>
                    </div>
                    <div data-title="Total Views">
                        <img src={singleClipHeaderTotalViewsTeal} alt="clip total views"/>
                    </div>
                </div>
                <div className="horizontal-container clip-list-text">
                    {this.renderMonthDropdowns()}
                    <div style={{width:50}}/>
                </div>
            </div>
        )
    }

    renderSingleClipViewColumnItem(imgSrc, testTag, imgAlt, text, modifier = "") {
        return (
            <div className="horizontal-container align-left">
                <div><img src = {imgSrc} alt={imgAlt}/></div>
                <div data-test={"field-" + testTag}> {text} <span className="single-clip-view-text-modifier">{modifier}</span></div> 
            </div>
        )
    }

    renderFirstColumn(cellMode) {
            const clip = this.props.clip
            return(
                <div className={cellMode ? "single-clip-first-column vertical-container align-left clip-list-text full-width" : "single-clip-first-column vertical-container align-left clip-list-text"}>
                     <div className="horizontal-container align-left">
                        <ClipThumbnail clip = {clip}/>
                        <span data-test="field-clip-name" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{clip.name} </span>
                    </div>

                    <div style={{height:10}} />

                    {this.renderSingleClipViewColumnItem(shared, "shared", "shared", "Shared")}

                    <div style={{height:10}} />

                    <div className="horizontal-container align-left" style={{width:"100%", alignContent: "left", justifyContent: "left" }}>
                        <img src = {UUID} alt="unique identifier" style={{minWidth:"50", display:"inline-block", verticalAlign: "middle"}}/>
                        <span data-test="field-clip-UUID" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >{clip.uri}</span>
                    </div>

                    <div style={{height:10}} />

                    <div className="horizontal-container align-left" style={{ position:"relative"}} >
                    <img src={Clip_link_icon} alt="copy clip link" onClick={() => {navigator.clipboard.writeText(clip.previewURL)}} />
                        <div data-test="btn-clip-copyURL" className="clip-link-button" style={{ position: "absolute", left: "0", top: "0", minWidth: "50", display: "inline-block", verticalAlign: "middle" }} alt="copy clip link" onClick={() => {navigator.clipboard.writeText(clip.previewURL)}}/>
                        <span style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{clip.previewURL}</span>
                    </div>
                </div>
            )
    }

    convertLastUpdatedToString(lastUpdated) {
        const date = new Date(lastUpdated);
        const locale = "en-US" //TODO swap out depending on localization
        return date.toLocaleDateString(locale)
    }
    
    renderSecondColumn(cellMode) {
        const clip = this.props.clip

        return(
            <div className="single-clip-second-column vertical-container align-left clip-list-text">
                {this.renderSingleClipViewColumnItem(minutes, "view-minutes", "view minutes", Math.floor(clip.totalViews / 30), "vm")}
                <div style={{height:10}} />
                {this.renderSingleClipViewColumnItem(date, "date", "date", this.convertLastUpdatedToString(clip.lastUpdated))}
                <div style={{height:10}} />
                {this.renderSingleClipViewColumnItem(SIZE, "clip-size", "clip size", Math.round(clip.bitrate * clip.durationInSeconds / 1024 / 1024), "mb")}
                <div style={{height:10}} />
                {this.renderSingleClipViewColumnItem(FPS, "clip-fps", "clip frames per second", clip.FPS)}
            </div>
        )
    }

    convertDurationToString(duration){
        if (duration > 10000000 || duration <= 0) {
            return "?"
        }
        const zeroPad = (num, places) => String(num).padStart(places, '0')
        const minutes = parseInt(duration / 60);
        const seconds = duration % 60;
        return minutes + ":" + zeroPad(seconds, 2)
    }

    renderThirdColumn(cellMode) {
        const clip = this.props.clip

        return(
            <div className="vertical-container align-right clip-list-text" style={{display: "inline-block", minWidth:120, width:cellMode ? "50%" : "10%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                {this.renderSingleClipViewColumnItem(length, "clip-length", "clip length", this.convertDurationToString(clip.durationInSeconds), "m:s")}
                <div style={{height:10}} />
                {this.renderSingleClipViewColumnItem(watched, "clip-viewPercent", "clip average watched", Math.round(clip.watched*100)/100, "%")}
                <div style={{height:10}} />
                {this.renderSingleClipViewColumnItem(unique, "clip-uniqueViews", "clip unique views", clip.uniqueViews)}
                <div style={{height:10}} />
                {this.renderSingleClipViewColumnItem(total, "clip-totalViews", "clip total views", clip.totalViews)}
            </div>
        )
    }

    render() {
        //If loading show spinner below header
        if(this.state.loading){
            return <div className="spinner" data-test="load-spinner-clips">
                <Oval
                height={100}
                width="100%"
                color="#9D8094"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#9D8094"
                strokeWidth={2}
                strokeWidthSecondary={2}
                />
            </div>    
        }

        // note (MS): these lines can be uncommented for checking that the view data for a single clip matches the one for all org clips.
        //console.log("compare", this.props.clip, this.state.singleClipInfo)
        // these two numbers should match:
        //console.log(this.props.clip.totalViews, this.state.singleClipInfo.totalViews / (Math.ceil(this.state.singleClipInfo.duration / 2)) )
        const viewportClassNames = `ViewportView SingleClipView`;
        const cellMode = this.props.width <= Constants.WIDTH_FOR_SHOWING_SIDEBAR
        return (
            <div className={viewportClassNames}>
                
                <div style={{position: "relative", height: "54px"}}>
                    <div className="user-logo-container-for-single-clip-view">
                        <UserLogo
                            userID = {this.props.userInfo ? this.props.userInfo.id : ""} 
                            orgID = {this.props.activeOrganization.id}
                            userInfo = {this.props.userInfo} 
                        />
                    </div>
                </div>
                
                <div className="portal-list-item">

                    {this.renderClipViewsHeader()}

                    <div id="content" className={"portal-list-item-content expanded"}>
                        <div className="inside inside-portal-list-item">
                             {this.renderClipViewsContent()}
                        </div>
                    </div>
                </div>

                {cellMode ? 
                    <div data-test="container-clip-fields" className="vertical-container">
                        {this.renderFirstColumn(cellMode)}
                        <div className="horizontal-container">
                            {this.renderSecondColumn(cellMode)}
                        {this.renderThirdColumn(cellMode)}
                        </div>
                    </div>
                :
                    <div data-test="container-clip-fields" className="horizontal-container full-width">
                        {this.renderFirstColumn(cellMode)}
                        {this.renderSecondColumn(cellMode)}
                        {this.renderThirdColumn(cellMode)}
                    </div>
                }

            </div>
        );
    }
}