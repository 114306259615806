import React from 'react'
import './welcome.css'
import ForgotPassSidebar from "./forgotpasssidebar"

export default class ForgotPassPage extends React.Component {
    render() {

        const viewportClassNames = `login-page`
        return (
            <div className={viewportClassNames}>
                <ForgotPassSidebar/>
                <div className="main-brand">
                </div>
            </div>
        );
    }
}