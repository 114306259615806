import React from 'react';
import * as Constants from '@/constants'
import './portalcliplistitem.css'

import UserLogo from "@/components/userlogo/userlogo"

export default class PortalClipListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menuExpanded: false, renaming: false}
    }

    processNewProjectClick() {
        this.props.createNewProject();
    }

    processDeleteProjectClick() {
        if (String(this.props.projectID) === "0") {
            return
        }
        this.props.deleteProject()
    }

    selectElementContents(el) {
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    processRenameProjectClick() {
        if (String(this.props.projectID) === "0") {
            return
        }
        this.setState({renaming: true});
        let el = document.getElementById(this.props.projectID);
        el.focus()
        this.selectElementContents(el);
    }
    
    onKeyPressed(key) {
        if (this.state.renaming && key === "Escape") {
            this.setState({renaming: false});
            document.getElementById(this.props.projectID).innerHTML = this.props.projectName;
        }
        else if (key === "Enter") {
            this.setState({ renaming: false });
            const el = document.getElementById(this.props.projectID)
            const newName = el.textContent || el.innerText
            this.props.renameProject(newName)
        }
    }
    
    renderProjectIcon(collapsedClass, toggleExpanded) {
        return (
            <div data-test="btn-pro-folder" className={collapsedClass} onClick={toggleExpanded}/>
        )
    }

    renderProjectTitle(projectID, projectName) {
        return (
            <div className="portal-list-item-title project-item-title" >
                <span data-test="field-pro-title" id={projectID} tabIndex="-1" contenteditable={this.state.renaming ? "true" : "false"} onKeyDown={e => this.onKeyPressed(e.key)} onBlur={() => this.onKeyPressed("Escape")}>    {projectName}  </span>
                <span style={{ fontStyle: "italic", paddingLeft: "4px" }}> ({this.props.clipNumber} clip{this.props.clipNumber !== 1 && <>s</>}) </span>
            </div>
        )
    }

    renderProjectMenu() {
        const disable = String(this.props.projectID) === "0"
        
        return (
            <div className="horizontal-container align-right clip-menu-container">
                <div data-test="btn-pro-dots"  className="clip-menu-button project-menu-button align-right" tabIndex="-1" onBlur={() => this.setState({menuExpanded : false})} onClick={() => this.setState({menuExpanded : !this.state.menuExpanded}) } >
                    <div className="clip-menu project-menu" style={{visibility: this.state.menuExpanded ? "visible" : "hidden"}}>
                        <div data-test="btn-pro-rename" className={disable ? "project-menu-item-disabled" : "clip-menu-item project-menu-item"} onClick={() => this.processRenameProjectClick()}> Rename Project </div>
                        <div data-test="btn-pro-create" className="clip-menu-item project-menu-item" onClick={() => this.processNewProjectClick()}> Create New Project </div>
                        <div data-test="btn-pro-delete" className={disable ? "project-menu-item-disabled" : "clip-menu-item project-menu-item"} onClick={() => this.processDeleteProjectClick()}> Delete Project </div>
                    </div>
                </div>
                {/* invisible component to align horizontally project-menu-button with clip-menu-button */}
                <div className="clip-link-button" style={{opacity:0}} />
            </div>
        )
    }


    renderListHeader(cellMode){
        let collapsedClass = "collapsed-folder-icon";
        if (this.props.expanded){
            collapsedClass = "expanded-folder-icon";
        }

        const maxIcons = cellMode ? 1 : 3

        let ownerIcons = [];
        for (let i = 0; i < Math.min(this.props.ownersInfo.length,maxIcons); i++){
            if (i === maxIcons - 1 && this.props.ownersInfo.length > maxIcons) {
                break;
            }
            ownerIcons.push(
                <div style={{paddingLeft:"4px"}}>
                    <UserLogo showFullNameOnHover onlyInitials={!this.props.ownersInfo[i].hasCustomPicture} userInfo={this.props.ownersInfo[i]} orgID={this.props.orgId} userID={this.props.ownersInfo[i].id} />
                </div>
            );
        }
        if (this.props.ownersInfo.length > maxIcons) {
            ownerIcons.push(
                <div className="user-picture-container" style={{paddingLeft:"4px"}}>
                    <div className="user-display-picture">
                        <div className="user-initials-container">
                            <div className="user-initials align-center"> 
                                +{this.props.ownersInfo.length - maxIcons + 1} 
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        // Columns layout and width must match that of ClipListRow -> render() and ClipsView -> renderTableHeader
        const columnsWidth = cellMode ? Constants.columnsWidthCell : Constants.columnsWidthDesktop
        // 6th Column (optional) and Last Column width
        const lastColumnWidth = cellMode ? columnsWidth[5] : columnsWidth[5] + columnsWidth[6]
        

        const toggleExpanded = this.props.toggleExpanded
        const projectID = this.props.projectID
        const projectName = this.props.projectName

        /* <div style={{ marginLeft: "0px", position: "relative", display: "flex", alignItems: "center", width: columnsWidth[0] + "%"}} >
                     {!draggingThisClip && this.renderClipPreview(clip)}
                     {this.renderClipName(clip, provided)}
                    <div className="horizontal-container align-right clip-menu-container" >
                        {!dragging && this.renderClipMenu()}
                        {!dragging && this.renderClipLinkButton(clip)}
                    </div>
                </div> */
        
        return(
            <div className="portal-clip-list-item-header">
            
                {/* 1st Column */}
                <div className="clip-or-project-first-column" style={{ width: columnsWidth[0] + "%", minWidth: columnsWidth[0] + "%" }}>
                    {this.renderProjectIcon(collapsedClass, toggleExpanded)}
                    {this.renderProjectTitle(projectID, projectName)}
                    {!this.props.dragging && this.renderProjectMenu()}
                </div>

                {/* 2nd and 3rd Columns */}
                <div className="horizontal-container align-left" style={{alignItems: "left", width: columnsWidth[1] + columnsWidth[2] + "%"}} />
               

                {/* 4th Column */}
                <div style={{ width: columnsWidth[3] + "%"}}>
                    <div data-test="text-pro-uniqueViews" className="clip-list-text">
                        {this.props.sumOfUniqueViews}
                    </div>
                </div>

                {/* 5th Column */}
                <div style={{ width: columnsWidth[4] + "%"}}>
                    <div data-test="text-pro-totalViews" className="clip-list-text">
                        {this.props.sumOfViews}
                    </div>
                </div>

                {/* 6th Column (optional) and Last Column */}
                <div style={{width: lastColumnWidth + "%", justifyContent:"flex-end"}}>
                    <div data-test="container-users" className="horizontal-container align-right" style={{paddingRight: "6px"}}>
                        {ownerIcons}
                    </div>
                </div>

            </div>
        );
    }

    render() {
        return (
            <div className={this.props.dragging ? "portal-list-item project-container-dragging" : "portal-list-item" } data-test="card-project">
                <div className="vertical-container full-width center-vert">
                    {this.renderListHeader(this.props.cellMode, this.props.columnWidth)}
                    
                    <div id="content" className={this.props.expanded ? "expanded full-width clips-content" : "full-width clips-content"} >
                        <div className="inside inside-portal-list-item" data-test="card-project-details">
                            {this.props.itemContent}
                        </div>
                    </div>
                </div>
                {this.props.placeholder}
            </div>
        )
    }
}