import React from 'react';
import './forminput.css'
import { checkNewPassword, checkIfTenChar, checkIfOneLowercase, checkIfOneUppercase, checkIfOneDigit, checkIfOneSpecialChar } from '@/components/passwordchecker/passwordchecker';

export default class FormInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.inputValue,
            passwordVisible: false,
            keyToDataTestDict: {
                "First Name": "field-org-firstName",
                "Last Name": "field-org-lastName",
                "Display Name": "field-org-displayName",
                "Professional Title": "field-org-professionalTitle",
                "Email": "field-org-email",
                "Nickname": "field-org-nickname",
                "Street Address": "field-org-streetAddress", 
                "City": "field-org-city", 
                "Postal Code": "field-org-postalCode", 
                "Region": "field-org-region",
                "New Password": "field-org-newPass", 
                "Confirm New Password": "field-org-confirmPass", 
            }
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        if (this.props.focusFirst){
            this.namedInput.focus();
        }
    }
    
    handleChange(event) {
        if (this.props.disabled && this.state.value !== "") {
            return;
        }
        this.props.inputUpdate(event.target.value);
        this.setState ({value: event.target.value});
    }

    togglePasswordVisible() {
        this.setState ({ passwordVisible: !this.state.passwordVisible})
    }

    render() {
        const inputType = this.props.password && !this.state.passwordVisible ? "password" : "text";
        return (
            <div className={this.props.label === "" ? "profile-input-filler" : 
               this.props.disabled ? "profile-input-disabled" : "profile-input"} style={{width:"100%"}}>
                {this.props.label !== "" && <><label>
                    {this.props.label}
                    </label>
                    <input readOnly={this.props.disabled} data-test={this.state.keyToDataTestDict[this.props.label]}
                    type={inputType} 
                    value={this.props.inputValue}
                    onChange={this.handleChange}
                    ref={(input) => { this.namedInput = input; }}>
                    </input>
                    {this.props.label.includes("Password") && <i className={
                        this.state.passwordVisible ? "eye-icon-for-password bi-eye" : "eye-icon-for-password bi-eye-slash"} onClick={() => this.togglePasswordVisible()}></i>}
                    <div className="profile-input-bottom-border"/></>
                }
                {this.props.label === "New Password" && !checkNewPassword(this.props.inputValue) && <div className="profile-input-error-message">
                    <span data-test="text-warn-length" className={checkIfTenChar(this.props.inputValue) ? "" : "red-text"}>length 10</span>, 
                    <span data-test="text-warn-upper" className={checkIfOneUppercase(this.props.inputValue) ? "" : "red-text"}> upper</span> & 
                    <span data-test="text-warn-lower" className={checkIfOneLowercase(this.props.inputValue) ? "" : "red-text"}> lower</span> case, 
                    <span data-test="text-warn-num" className={checkIfOneDigit(this.props.inputValue) ? "" : "red-text"}> number</span> & 
                    <span data-test="text-warn-special" className={checkIfOneSpecialChar(this.props.inputValue) ? "" : "red-text"}> special </span> char</div>}
                {this.props.label === "Confirm New Password" && !this.props.passwordsMatch && <div data-test="text-warn-match" className="profile-input-error-message red-text"> Don't match </div>}

            </div>
        );
    }
}