export const WIDTH_FOR_MOBILE_VIEW = 600;
export const WIDTH_FOR_SHOWING_SIDEBAR = 800;
export const WIDTH_FOR_DESKTOP_VIEW = 1100;

export const viewStates = {
            user: "USER",
            home: "Home", // currently not used
            clips: "CLIPS",
            compute: "COMPUTE",
            downloads: "DOWNLOADS",
            singleClip: "SingleClip", // not reflected in sidebar as per design
        }

export const localStoreKey = "ArcturusPortalAuth"
export const localStoreRememberEmailKey = "RememberEmail"

export const tenDukeKeys = {
    "First Name": "firstName", "Last Name": "lastName", "Display Name": "displayName", "Professional Title": "professionalTitle", "Nickname": "nickname",
    "Street Address": "streetAddress", "City": "locality", "Postal Code": "postalCode", "Region": "region", "Country": "country",
    "Email": "email", "New Password": "New Password", "Confirm New Password": "Confirm New Password"
}

export const arcturusOrgId = "d178ad85-3f1e-4488-8e59-84a9774963fb"

export const columnsWidthDesktop = [40, 10, 10, 10, 10, 10, 10]
export const columnsWidthCell = [50, 10, 10, 10, 10, 10]