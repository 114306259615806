import React from 'react';

import './orglogo.css'

import { PictureService } from '@/services/pictureservice';

import arcturusImageWhite from "@/images/arcturus.svg";

export default class OrgLogo extends React.Component {

    // note (MS): could be improved by storing in the DB, like I'm doing for clips thumbnails (probably best to do once that is merged)
    // So we don't call s3 for non-existing pics and get console errors. 
    onPicFailed() {
        if (this.props.setOrgHasCustomPicture) {
            this.props.setOrgHasCustomPicture(this.props.orgID, false)
        }
    }

    render() {
        return(     
            <img className="org-icon"
            onError={() => this.onPicFailed()}
            src={this.props.hasCustomPicture ? PictureService.getOrgPictureURL(this.props.orgID) : 
                arcturusImageWhite} alt={"company logo"}/>
        )
    }
}