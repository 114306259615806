import React from 'react';
import './clipthumbnail.css'
import playIcon from "@/images/viewport/components/clipthumbnail/Play_Card_icon.svg";

export default class ClipThumbnail extends React.Component {

    onPicFailed() {
        this.props.setClipHasThumbnail(this.props.clip, false)
    }

    //TODO handle object expiry without using `new Date();` we could support caching of the images unless they've actually been changed
    render() {
        return( 
        <a data-test="btn-preview-img" href={this.props.clip.previewURL} target="_blank" rel="noreferrer">
            <div className="clip-preview-container">
                {this.props.clip.thumbnailURL && <img className="clip-preview" onError={() => this.onPicFailed()}
                    src={this.props.clip.thumbnailURL+"?" + new Date()} alt="clip thumbnail" />}
                <img className="clip-play-icon" alt="clip icon" src={playIcon}/>
            </div>
        </a>
        )
    }
}