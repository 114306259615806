import React from 'react';

import './sidebar.css'

import clipsButtonImage from "@/images/sidebar/Clips_icon.svg";
import computeButtonImage from "@/images/sidebar/Compute_icon.svg";
import downloadsButtonImage from "@/images/sidebar/Downloads_icon.svg"
import HoloSuiteImageWhite from "@/images/sidebar/HoloSuiteImageWhite.svg"
import logoutButtonImage from "@/images/sidebar/Logout_icon.svg"
import OrgLogo from "@/components/orglogo/orglogo"
import UserLogo from "@/components/userlogo/userlogo"

import * as Constants from '@/constants'

import { AuthService } from '@/services/authservice';


export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {sidebarExpanded: false}
    }

    processButtonClick(viewState) {
        this.props.setActiveView(viewState); 
        this.setState({sidebarExpanded : false})
    }

    renderButton(viewState, buttonImage){
        let buttonClassName = "sidebar-button";
        let imageClassName = "sidebar-button-image";
        const dataTestLabels = {}
        dataTestLabels[Constants.viewStates.clips] = "sidebar-clips"
        dataTestLabels[Constants.viewStates.compute] = "sidebar-compute"
        dataTestLabels[Constants.viewStates.downloads] = "sidebar-downloads"
        
        if (this.props.activeView === viewState){
            buttonClassName = "active-sidebar-button";
            imageClassName = "active-sidebar-button-image";
        }

        return (
            <div className={buttonClassName} data-test={dataTestLabels[viewState]} onClick={() => this.processButtonClick(viewState)}>
                <img className={imageClassName} src={buttonImage} alt={"sidebar button"}/>
                <div className="sidebar-hover-panel">
                    <div>
                        {viewState}
                    </div>
                </div>
            </div>
        )
    }

    renderUserButton(){
        let buttonClassName = "sidebar-button";

        if (this.props.activeView === Constants.viewStates.user){
            buttonClassName = "active-sidebar-button";
        }
        return (
            <div className={buttonClassName} data-test="sidebar-profile" onClick={() => this.processButtonClick(Constants.viewStates.user)}>
                 
                <UserLogo
                    userID = {this.props.userInfo ? this.props.userInfo.id : ""} 
                    orgID = {this.props.activeOrganization.id} 
                    userInfo = {this.props.userInfo}
                    invertColors = {this.props.activeView !== Constants.viewStates.user}
                    imgUpdater = {this.props.sidebarPictureRefresher} 
                    />
                <div className="sidebar-hover-user-panel sidebar-hover-panel">
                    <div className='username'>
                        PROFILE
                    </div>
                    <div data-title="Logout" className="horizontal-container">
                        <img className="sidebar-logout" data-test="sidebar-logout" src={logoutButtonImage} onClick={this.logout} alt='Logout'/>    
                    </div>
                </div>
            </div>
        )
    }

    logout(clickEvent){
        clickEvent.stopPropagation();
        AuthService.logout()
    }

    renderHiddenSidebarButton() {
        return (<div data-test="btn-show-sidebar" className="hidden-sidebar-button" tabIndex="-1" 
                    onBlur={() => this.setState({sidebarExpanded : false})} 
                    onClick={() => this.setState({sidebarExpanded : !this.state.sidebarExpanded}) }>
                </div>)
    }

    render() {
        return (
            <>
                {this.props.hidden && this.renderHiddenSidebarButton()}
                <div className="sidebar main-sidebar" id={this.props.hidden && !this.state.sidebarExpanded ? "hidden-sidebar" : "noID"}>
                    {this.renderUserButton()}
                    {this.renderButton(Constants.viewStates.clips, clipsButtonImage)}
                    {this.renderButton(Constants.viewStates.compute, computeButtonImage)}
                    {this.renderButton(Constants.viewStates.downloads, downloadsButtonImage)}

                    <div className="org-icon-in-sidebar">
                        <OrgLogo sidebar
                            orgID = {this.props.activeOrganization.id} 
                            imgUpdater = {this.state.imgUpdater}
                            hasCustomPicture = {this.props.activeOrganization.hasCustomPicture}
                            setOrgHasCustomPicture = {(organization, value) => this.props.setOrgHasCustomPicture(organization, value)}
                            />
                    </div>
                    <div id="org-label-in-sidebar">{this.props.orgHoverMessage}</div>

                    <div className="holosuite-icon-in-sidebar">
                        <img className="org-icon" src={HoloSuiteImageWhite} alt={"HoloSuite"}/>
                    </div>
                    
                </div>
            </>
        );
    }
}