export class Organization{
    constructor(organizationData){
        this.authorId = organizationData.authorId;
        this.authoredDate = organizationData.authored;
        this.description = organizationData.description;
        this.id = organizationData.id;
        this.name = organizationData.name;
        this.type = organizationData.type;
        this.expanded = false // used to control expand state in UI
        this.hasCustomPicture = true;
    }
}

export class Project{
    clips = []

    constructor(projectData, streamApiURL) {
        this.id = projectData.projectID
        this.name = projectData.name || "Project Name Not Found"
        this.createdBy = projectData.createdBy || "Project Owner Not Found"
        this.hasMenuExpanded = false // used to control overflow in UI
        this.streamApiURL = streamApiURL
    }
}

export class Clip{
    constructor(clipData, streamApiURL){

        const totalPlayCount = Number(clipData.totalViews)
        const cappedPlayCount = Number(clipData.cappedViews)

        const durationInSeconds = clipData.duration || 0

        // estimate each play in totalPlayCount = 2s
        const chunksInClip = Math.ceil (durationInSeconds / 2)
        const totalViews = totalPlayCount !== "?" && durationInSeconds !== 0 ?
            Math.floor(totalPlayCount / chunksInClip) : "0"

        this.id = String(clipData.id) //Set as string to support drag and drop ids, required
        this.uri = clipData.uri
        this.name = clipData.name || "name not found"
        this.description = clipData.description || "No Description Found"
        this.durationInSeconds = durationInSeconds
        this.projectID = clipData.projectID || 0
        this.ownerID = clipData.createdBy || "Owner ID Not Found"
        this.ownerInfo = {firstName: "", lastName: ""} // to be completed after creation
        this.uniqueViews = Number(clipData.uniqueViews)
        this.totalViews =  totalViews //=== "?" ? this.uniqueViews : Math.max(totalViews, this.uniqueViews)
        this.watched = durationInSeconds > 0 && this.uniqueViews !== 0 && cappedPlayCount !== 0 ? cappedPlayCount * 100 / ( this.uniqueViews * chunksInClip ) : 0
        this.lastUpdated = clipData.lastUpdated || "Date Not Found"
        this.streamApiURL = streamApiURL
        this.previewURL = `${streamApiURL}/media/play/${clipData.uri}`
        this.thumbnailURL = clipData.thumbnailURL || ""
        this.FPS = clipData.FPS
        this.bitrate = clipData.bitrate
        this.lastMonthsViews = clipData.lastMonthsViews
    }
}