import React from 'react'
import './welcome.css'
import SignUpSidebar from "./signupsidebar"
import welcomeImg from "@/images/welcome/welcome.svg"

export default class SignUpPage extends React.Component {
    render() {

        const viewportClassNames = `login-page`
        return (
            <div className={viewportClassNames}>
                <SignUpSidebar/>
                <div className="main-brand">
                    <img className="welcome-to-img" src={welcomeImg} />
                </div>
            </div>
        );
    }
}