import axios from "axios"

const apiURL = process.env.REACT_APP_PORTAL_API_URL

export let DownloadsService = (() => {

    //public methods
    return {
        getDownloads: async (orgName) => {
            try {
                let response = await axios.post(apiURL+'/downloads', {orgName: orgName});
                return response.data;
            }
            catch (error) {
                console.log("Error retrieving downloads", error)
                return
            }
        },

    };
  })();