import React from 'react';

import './viewport.css'
import './hsplayer.css'

import ClipsView from './clipsview/clipsview';
import ComputeView from './computeview/computeview';
import HomeView from './homeview/homeview';
import UserView from './userview/userview';
import DownloadsView from './downloadsview/downloadsview';
import SingleClipView from './singleclipview/singleclipview';

import * as Constants from '@/constants'

export default class Viewport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            thumbnailToCrop: "",
            previewingForClip: "",
            croppingForClip: "",
            activeClip: "",
        }
    }

    /* Start of HoloStream player initialization code */
    initializeScene(holoStreamInstance){
        let threeObjects = {
            threeScene: holoStreamInstance.getThreeScene(), 
            threeCanvas: holoStreamInstance.getHoloStreamCanvas(),
            threeRenderer: holoStreamInstance.getThreeRenderer(),
            threeCamera: holoStreamInstance.getThreeCamera()
        };

        let fillColor = new window.THREE.Color("#aaa");
        threeObjects.threeScene.background = fillColor;
        threeObjects.threeScene.fog = new window.THREE.FogExp2(fillColor, 0.05);

        // Create the light to cast the shadows
        var light = new window.THREE.PointLight( 0xffffff, 1, 100 );
        light.position.set( -10, 10, 10 );
        light.castShadow = true;
        light.shadow.mapSize.width = 2048;
        light.shadow.mapSize.height = 2048;

        threeObjects.threeScene.add( light );

        // Create the surface to receive the shadows
        var fillGeometry = new window.THREE.PlaneGeometry(100, 100, 32);
        fillGeometry.translate(0, 0, 0);
        fillGeometry.rotateX(-90 / 57.2958);

        var fillMaterial = new window.THREE.MeshPhongMaterial( {
            color: new window.THREE.Color("#444")
        });
            
        var fillPlane = new window.THREE.Mesh( fillGeometry, fillMaterial );
        fillPlane.receiveShadow = true;
        threeObjects.threeScene.add( fillPlane );

        // Add camera controls
        let controls = new window.THREE.OrbitControls( threeObjects.threeCamera, threeObjects.threeRenderer.domElement );
        controls.enableDamping = true;
        controls.dampingFactor = 0.09;
        controls.enableKeys = true;
        controls.autoRotate = false;
        controls.autoRotateSpeed = 2.0;
        controls.target = new window.THREE.Vector3(0, 1, 0);
        controls.maxPolarAngle = Math.PI * .6;
        controls.minPolarAngle = Math.PI * .25;
        controls.update();
    }

    componentDidMount() {
        if (!this.state.holoStream) {
            let holostreamOptions = {
                debugEnabled: false,
                targetContainerDivID: "clip-thumbnail-creator",
                hideUI: false
            }
            this.setState ({holoStream : new window.HoloStream(holostreamOptions) }, function() {this.initializeScene(this.state.holoStream)} )
        }
    }
    /* End of HoloStream player initialization code */

    renderView(width, height){
        const organizations = this.props.organizations
        const activeOrganization = this.props.activeOrganization
        const userInfo = this.props.userInfo

        switch(this.props.activeView){
            case Constants.viewStates.user:
                return(
                    <UserView
                        setActiveOrganization={organization => this.props.setActiveOrganization(organization)}
                        setOrgHasCustomPicture={(organization, value) => this.props.setOrgHasCustomPicture(organization, value)}
                        activeOrganization={activeOrganization}
                        organizations = {organizations}
                        width={width}
                        height={height}
                        userInfo = {userInfo}
                        updateUserInfo = {userInfo => this.props.updateUserInfo(userInfo)}
                        refreshSidebarPicture = {() => this.props.refreshSidebarPicture()}
                        arcturusUser = {this.props.arcturusUser}
                    />
                );
            case Constants.viewStates.home:
                return(
                    <HomeView/>
                );
            case Constants.viewStates.clips:
                return(
                    <ClipsView
                        activeOrganization={activeOrganization}
                        width={width}
                        height={height}
                        userInfo = {userInfo}
                        setActiveView={view => this.props.setActiveView(view)}
                        setActiveClip={clip => this.setState({activeClip: clip})}
                        setClipForThumbnailGeneration = {clip => this.setClipForThumbnailGeneration(clip)}
                        setClipForThumbnailUpload = {clip => this.setClipForThumbnailGeneration(clip)}
                        setThumbnailToCrop = {thumbnailToCrop => this.setState({thumbnailToCrop: thumbnailToCrop})}
                        thumbnailToCrop = {this.state.thumbnailToCrop}
                        setCroppingForClip = {clip => this.setState({croppingForClip: clip})}
                        croppingForClip = {this.state.croppingForClip}
                        clips={this.props.clips}
                        ownersInfo={this.props.ownersInfo}
                        projects={this.props.projects}
                        setOrgProjects={projects => this.props.setOrgProjects(projects)}
                    />
                );
            case Constants.viewStates.compute:
                return(
                    <ComputeView
                        setActiveOrganization={organization => this.props.setActiveOrganization(organization)}
                        activeOrganization={activeOrganization}
                        organizations = {organizations}
                        userIsAdmin={true} //TODO populate this via property associated with user pulled from DB record
                        userInfo = {userInfo}
                        refreshSidebarPicture = {() => this.props.refreshSidebarPicture()}
                        orgViews={this.props.orgViews}
                        width={width}
                        computeUsage={this.props.computeUsage}
                        setComputeUsage={usage => this.props.setComputeUsage(usage)}
                        computeEntitlement={this.props.computeEntitlement}
                        setComputeEntitlement={ent => this.props.setComputeEntitlement(ent)}
                        viewEntitlement={this.props.viewEntitlement}
                        setViewEntitlement={ent => this.props.setViewEntitlement(ent)}
                    />
                );
            case Constants.viewStates.downloads:
                return(
                    <DownloadsView
                        activeOrganization={activeOrganization}
                        width={width}
                        height={height}
                        setOrgDownloads={downloads => this.props.setOrgDownloads(downloads)}
                        downloads={this.props.orgDownloads}
                    />
                );
            case Constants.viewStates.singleClip:
                return(
                    <SingleClipView
                        activeOrganization={activeOrganization}
                        width={width}
                        userInfo = {userInfo}
                        height={height}
                        clip={this.state.activeClip}
                    />
                );
            default:
                return (
                    <div>none</div> //TODO create an error component
                );
        }
    }

    setClipForThumbnailGeneration(clip) {
        this.setState ({previewingForClip: clip, thumbnailToCrop: "", croppingForClip: ""})
        if (clip !== "") {
            this.state.holoStream.openURL(clip.streamApiURL + "/media/" + clip.uri + "/stream/dash/manifest.mpd")
        }
    }

    onGenerateThumbnail() {  
        try {
            this.state.holoStream.handlePlay(false)
            this.state.holoStream.threeWrapper.render();
            const imgData = this.state.holoStream.getThreeRenderer().domElement.toDataURL("image/jpeg")
            this.setState({thumbnailToCrop : imgData, croppingForClip: this.state.previewingForClip})
        }
        catch (err) {
            console.log(err)
        }
    }

    onCancelThumbnailGeneration() {
        this.state.holoStream.handlePlay(false)
        this.setState({previewingForClip:""})
    }
    
    renderClipThumbnailGenerationContainer() {
        // we render the component even if it shouldn't be shown so holoStream can initialize using the div with id="clip-thumbnail-creator"
        const shouldShow = this.state.thumbnailToCrop === "" && this.state.previewingForClip !== ""
        const containerWidth = Math.max(this.props.width / 3 * 2, 400) 
        const containerHeight = Math.max(this.props.height / 3 * 2, 250)
        
        return(
            <div className="dialog-window-background-blocker" style={{ visibility: shouldShow ? "visible": "hidden" }}>
                <div className="userImageCroppingContainer" style={{ top: shouldShow ? 50 : this.props.height, width: containerWidth, height: containerHeight, visibility: shouldShow ? "visible": "hidden"}}>
                    {/* We use opacity to prevent play/pause buttons from the HS player to be displayed when they shouldn't */}
                    <div id="clip-thumbnail-creator" style={{width:containerWidth - 20, height: containerHeight - 100, opacity:shouldShow ? 1: 0}}> 
                    </div>

                    <div style={{height:"25px"}}/>

                    <div className="full-width">
                        <div className="horizontal-container full-width center">
                            <div className="portal-button" onClick={() => this.onCancelThumbnailGeneration()}> CANCEL </div>
                            <div style={{width:"50px"}}/>
                            {this.state.thumbnailToCrop === "" && <div className="portal-button" onClick={() => this.onGenerateThumbnail()}> GENERATE THUMBNAIL </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let className="viewport";
        if (this.props.sidebarHidden){
            className += " viewport-sidebar-hidden"
        }
        
        return (          
            <div className={className}>
                {this.renderView(this.props.width, this.props.height)}
                {this.renderClipThumbnailGenerationContainer()}
            </div>
        );
    }
}