import React from 'react';


import './downloadsheaderitem.css'

import triangleIcon from "@/images/viewport/triangleIcon.svg"
import triangleIconFilled from "@/images/viewport/triangleIconFilled.svg"

export default class DownloadsHeaderItem extends React.Component {

    render() {
        let buttonFunction = (state) => this.props.setArchiveVisible(!state);

        return (
            <div className="horizontal-container full-width h4-line"> 
                <div data-test="btn-exp-tri" className={this.props.archiveVisible ? "triangle" : "triangle rotated"}  onClick={() => buttonFunction(this.props.archiveVisible)}>
                    <img src={triangleIcon} alt="triangle icon"></img>
                    <img src={triangleIconFilled} className="filled-triangle" alt="triangle icon"></img>
                </div>
                <div style={{width: "260px"}}>
                    <div className="align-left downloads-header-text">
                        {this.props.label}
                    </div>
                </div>
            </div>
        );
    }
}